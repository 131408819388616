import React from 'react';
import styles from './Logo.module.scss';

const Logo = ({ logoURL = '' }) => (
  <div>
    {logoURL ? <img className={styles.logo} src={logoURL} alt="Apiro" /> : null}
  </div>
);

export default Logo;
