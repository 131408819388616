import React from 'react';
import CustomTable from '../../../../components/CustomTable';
import { useUser } from '../../../../store';

const RolesList = (props: any) => {
  const [user, _] = useUser();

  const headers: any[] = props.headers;

  return (
    <CustomTable headers={headers} tableData={user.applicationRolesList} />
  );
};

export default RolesList;
