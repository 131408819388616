import React, { useState } from 'react';
import styles from './AssignForms.module.scss';
import UsersList from '../../Users/UsersList';
import { useUser, useForm } from '../../../../store';
import { assignForms, fetchUsers } from '../../../../api/strapi';
import { LocaleStoreState } from '../../../../store/locale-store';
import CustomTable from '../../../../components/CustomTable';
import { NavLink } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import { ROLES } from '../../../../constants';
import CustomInput from '../../../../components/CustomInput';
import { FaCheckCircle } from 'react-icons/fa';
import { FaInfoCircle } from 'react-icons/fa';

type AssignFormsProps = {
  locale: LocaleStoreState;
  match: any;
};

const AssignForms = ({ locale, match }: AssignFormsProps) => {
  const [user, userActions] = useUser();
  const [form, _] = useForm();

  const [selectedUser, setSelectedUser]: any = useState({});
  const [selectedUserAssignedForms, setSelectedUserAssignedForms] = useState(
    []
  );

  const [openAssignFormsDialog, setOpenAssignFormsDialog] = useState(false);
  const [formList, setFormList]: any = useState([]);
  const [selectedFormsToAssign, setSelectedFormsToAssign]: any[] = useState([]);
  const [role, setRole]: any = useState(null);

  const [formSearchText, setFormSearchText] = useState('');

  React.useEffect(() => {
    setFormList([...form.formsList]);
    setRole(
      user.applicationRolesList[
        user.applicationRolesList.findIndex(
          (role: any) => role.name === ROLES.FINANCIALADVISERCLIENT
        )
      ].id
    );

    if (match.params.userId) {
      onUserSelection(
        user.usersList[
          user.usersList.findIndex(
            (userData: any) => userData.id == match.params.userId
          )
        ]
      );
    }
  }, [user.usersList, role]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenAssignFormsDialog = () => {
    setOpenAssignFormsDialog(true);
  };

  const handleCloseAssignFormsDialog = () => {
    setOpenAssignFormsDialog(false);
  };

  const handleAssignForms = () => {
    setOpenAssignFormsDialog(false);
    assignForms(selectedUser.id, selectedFormsToAssign).then((data: any) => {
      setSelectedUser({ ...data });

      let assignedFormsCopy: any = selectedFormsToAssign;
      data.doc_templates.map((doc: any) => {
        if (!(assignedFormsCopy.indexOf(doc.id) > -1)) {
          assignedFormsCopy.push(doc.id);
        }
      });
      setSelectedFormsToAssign(assignedFormsCopy);

      setSelectedUserAssignedForms(data.doc_templates);

      fetchUsers().then((data: any) => {
        userActions.setUsersList([...data]);
      });
    });
  };

  const handleRemoveAssignedForm = (formId: number) => {
    let assignedFormsCopy: any = selectedFormsToAssign;
    let formIndex: number = assignedFormsCopy.indexOf(formId);

    assignedFormsCopy.splice(formIndex, 1);
    setSelectedFormsToAssign(assignedFormsCopy);

    handleAssignForms();
  };

  const onUserSelection = (userData: any) => {
    setSelectedUser({
      ...userData,
    });
    if (userData && userData.doc_templates) {
      setSelectedUserAssignedForms(userData.doc_templates);
      let assignedForm: [] = userData.doc_templates.map((doc: any) => doc.id);
      setSelectedFormsToAssign(assignedForm);
    }
  };

  const headers: any[] = [
    {
      type: 'text',
      key: 'first_name',
      value: locale.values?.label?.firstNameLabel,
    },
    {
      type: 'text',
      key: 'last_name',
      value: locale.values?.label?.lastNameLabel,
    },
    {
      type: 'text',
      key: 'email',
      value: locale.values?.label?.emailLabel,
    },
    {
      type: 'text',
      key: 'id',
      value: locale.values?.label?.clientIdLabel,
    },
  ];

  const assignedFormHeaders: any[] = [
    {
      type: 'text',
      key: 'doc_name',
      value: locale.values?.label?.documentNameLabel,
    },
    {
      type: 'text',
      key: 'doc_category',
      value: locale.values?.label?.categoryLabel,
    },
    {
      type: 'container',
      key: 'container',
      value: locale.values?.label?.submittedLabel,
      container: (props: any) =>
        props.itemData?.submitted ? (
          <FaCheckCircle color={'green'} />
        ) : (
          <FaInfoCircle color={'orange'} />
        ),
    },
    {
      type: 'actionContainer',
      key: 'actionContainer',
      value: locale.values?.label?.actionsLabel,
      actionContainer: (props: any) => (
        <NavLink
          className={styles.navAnchor}
          activeClassName={styles.navActive}
          to={`/apiroforms/forms/view/${props.itemData.id}`}
        >
          <button className={styles.assignFormsBtn}>
            {locale.values?.label?.viewLabel}
          </button>
        </NavLink>
      ),
    },
    (match.params.userId === undefined || match.params.userId === null) && {
      type: 'actionsListIcon',
      key: 'actions',
      value: '',
      actions: [
        {
          key: 'remove',
          value: locale.values?.label?.removeLabel,
          callback: (formData: any) => handleRemoveAssignedForm(formData.id),
        },
      ],
    },
  ];

  const formListHeaders: any[] = [
    {
      type: 'text',
      key: 'doc_name',
      value: locale.values?.label?.documentNameLabel,
    },
    {
      type: 'actionContainer',
      key: 'actionContainer',
      value: locale.values?.label?.actionsLabel,
      actionContainer: (props: any) => (
        <Checkbox
          checked={selectedFormsToAssign.indexOf(props.itemData.id) > -1}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={() => {
            let assignedFormsCopy: any = selectedFormsToAssign;
            let index: number = assignedFormsCopy.indexOf(props.itemData.id);
            if (index > -1) {
              assignedFormsCopy.splice(index, 1);
              setSelectedFormsToAssign([...assignedFormsCopy]);
            } else {
              assignedFormsCopy.push(props.itemData.id);
              setSelectedFormsToAssign([...assignedFormsCopy]);
            }
          }}
        />
      ),
    },
  ];

  React.useEffect(() => {
    if (formSearchText) {
      setFormSearchText(formSearchText.toLowerCase());
      setFormList(
        form.formsList.filter(
          (item: any) =>
            item['doc_name'] &&
            item['doc_name'].toLowerCase().includes(formSearchText)
        )
      );
    } else {
      setFormList(form.formsList);
    }
  }, [formSearchText]);

  return (
    <div>
      {(match.params.userId === undefined || match.params.userId === null) && (
        <div className={styles.toolbar}>
          <h3>{locale.values?.label?.clientsLabel}</h3>
        </div>
      )}
      <div className={styles.mainContainer}>
        {(match.params.userId === undefined ||
          match.params.userId === null) && (
          <div>
            <UsersList
              headers={headers}
              searchText={''}
              selectedRole={role}
              locale={locale}
              data={user.usersList.filter(
                (item: any) => item['role']['id'] === role
              )}
              onItemClick={(item: any) => onUserSelection(item)}
            />
          </div>
        )}
        <div>
          {selectedUser.email && (
            <div>
              <h3>
                {selectedUser.first_name} {selectedUser.last_name}
              </h3>
              <h4>{selectedUser.email}</h4>
              <p>{selectedUser.id}</p>
            </div>
          )}
          {!selectedUser.email && (
            <div>
              <h4>{locale.values?.label?.noUserSelectedLabel}</h4>
            </div>
          )}
          <div className={styles.toolbar}>
            <h4>{locale.values?.label?.assignedFormsLabel}</h4>
            {(match.params.userId === undefined ||
              match.params.userId === null) && (
              <div>
                <button
                  className={`${styles.assignedFormBtn} ${
                    !(selectedUser.id >= 0) && styles.saveBtnDisabled
                  }`}
                  disabled={!(selectedUser.id >= 0)}
                  onClick={handleOpenAssignFormsDialog}
                >
                  {locale.values?.label?.assignFormsLabel}
                </button>
              </div>
            )}
          </div>
          <CustomTable
            headers={assignedFormHeaders}
            tableData={selectedUserAssignedForms}
          />
        </div>
      </div>

      <Dialog
        open={openAssignFormsDialog}
        onClose={handleCloseAssignFormsDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {locale.values?.label?.assignFormsLabel}
        </DialogTitle>
        <DialogContent>
          <CustomInput
            value={formSearchText}
            onChange={(e: any) => setFormSearchText(e.target.value)}
            placeholder={locale.values?.label?.searchLabel}
            errorMessage={''}
          />
          <CustomTable headers={formListHeaders} tableData={formList} />
        </DialogContent>
        <DialogActions>
          <button
            className={styles.cancelBtn}
            disabled={false}
            onClick={handleCloseAssignFormsDialog}
          >
            {locale.values?.label?.cancelLabel}
          </button>
          <button
            className={`${styles.saveBtn} ${0 && styles.saveBtnDisabled}`}
            disabled={false}
            onClick={handleAssignForms}
          >
            {locale.values?.label?.assignFormsLabel}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AssignForms;
