import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LocaleStoreState } from '../../store/locale-store';
import Header from '../../components/Header';
import styles from './Configuration.module.scss';

type ConfigurationProps = RouteComponentProps<{}> & {
  locale: LocaleStoreState;
};

function Configuration(props: ConfigurationProps) {
  return (
    <React.Fragment>
      <Header locale={props.locale} />
      <div className={styles.pageWrapper}>Configuration Page</div>
    </React.Fragment>
  );
}

export default Configuration;
