import React, { useState } from 'react';
import styles from './ManageUsers.module.scss';
import UsersList from '../UsersList';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import CustomInput from '../../../../components/CustomInput';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { useUser } from '../../../../store';
import { deleteUser } from '../../../../api/strapi';
import { LocaleStoreState } from '../../../../store/locale-store';
import { STRAPI } from '../../../../constants';
import { BsPeopleCircle } from 'react-icons/bs';

type ManageUsersProps = {
  locale: LocaleStoreState;
  match: any;
};

const ManageUsers = ({ locale, match }: ManageUsersProps) => {
  const [user, _] = useUser();
  const history: any = useHistory();

  const headers: any[] = [
    {
      type: 'container',
      key: 'container',
      value: locale.values?.label?.submittedLabel,
      container: (props: any) =>
        props.itemData?.profile_image?.url ? (
          <img
            className={styles.profileImage}
            src={`
              ${STRAPI.BASE_URL}${props.itemData?.profile_image?.url}`}
          />
        ) : (
          <BsPeopleCircle size={50} color={'#e0e0e0'} />
        ),
    },
    {
      type: 'text',
      key: 'first_name',
      value: locale.values?.label?.firstNameLabel,
    },
    {
      type: 'text',
      key: 'last_name',
      value: locale.values?.label?.lastNameLabel,
    },
    {
      type: 'text',
      key: 'email',
      value: locale.values?.label?.emailLabel,
    },
    {
      type: 'text',
      key: 'role',
      subKey: 'name',
      value: locale.values?.label?.roleLabel,
    },
    {
      type: 'actionsListIcon',
      key: 'actions',
      value: locale.values?.label?.actionsLabel,
      actions: [
        {
          key: 'edit',
          value: 'Edit',
          callback: (userData: any) =>
            history.push(`/apiroforms/clients/edit/${userData.id}`),
        },
        {
          key: 'remove',
          value: 'Remove',
          callback: (userData: any) => deleteUser(userData.id),
        },
      ],
    },
  ];

  const [searchText, setSearchText] = useState('');
  const [role, setRole] = useState(match.params.roleId || 0);

  return (
    <div>
      <div className={styles.toolbar}>
        <div className={styles.toolbarLeftPart}>
          <div>
            <CustomInput
              value={searchText}
              onChange={(e: any) => setSearchText(e.target.value)}
              placeholder={locale.values?.label?.addUserLabel}
              errorMessage={''}
            />
          </div>
          <div>
            <FormControl
              variant="outlined"
              className={styles.dropdown}
              margin="dense"
            >
              <Select
                native
                value={role}
                onChange={(event: any) => setRole(event.target.value)}
                inputProps={{
                  name: 'role',
                  id: 'outlined-age-native-simple',
                }}
              >
                <option key={0} value={0}>
                  {'All'}
                </option>
                {user.applicationRolesList.map((role: any) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <NavLink
            className={styles.navAnchor}
            activeClassName={styles.navActive}
            to="/apiroforms/clients/add"
          >
            <button className={styles.addClientBtn}>
              {locale.values?.label?.addUserLabel}
            </button>
          </NavLink>
        </div>
      </div>
      <UsersList
        headers={headers}
        searchText={searchText}
        selectedRole={role}
        locale={locale}
        onItemClick={(item: any) => console.log(item)}
        data={user.usersList}
      />
    </div>
  );
};

export default ManageUsers;
