import React from 'react';
import { useUser } from '../../store';
import { refreshUser as refreshUserAPI } from '../../api/user';

const fetchLoadData = async () => {
  const userData = await refreshUserAPI();
  let idToken;

  if (userData) {
    const { user } = userData;
    if (user) {
      idToken = await user.getIdToken();
      // const email = user.email as string;
    }
  }

  return { userData, idToken };
};

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [userState, userActions] = useUser();

  React.useEffect(() => {
    fetchLoadData().then(({ userData, idToken }) => {
      if (userData) {
        const { user, role } = userData;
        if (user) {
          const email = user.email as string;
          userActions.authenticate(email, user, idToken as string, role);
        }
      } else {
        userActions.setAuthCheckDone(true);
      }
    });
  }, [userActions]);

  if (!userState.authCheckDone) return null;

  return <>{children}</>;
}
export default AuthProvider;
