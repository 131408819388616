export const defaultPublicLocale = {
  Style: {
    logo512_png: {
      formats: {
        thumbnail: {
          url: process.env.PUBLIC_URL + '/logo_apiro_full512.png',
        },
      },
    },
  },
};

export const defaultUserLocale = {
  label: {
    menuItemDashboard: 'DASHBOARD',
    apiroFormsLabel: 'Apiro Forms',
    usernameLabel: 'Username',
    emailLabel: 'Email',
    clientIdLabel: 'Client ID',
    roleLabel: 'Role',
    actionsLabel: 'Actions',
    nameLabel: 'Name',
    descriptionLabel: 'Description',
    noOfUsersLabel: 'No. of users',
    roleNameRequired: 'Role name is required.',
    roleDescriptionRequired: 'Role description is required.',
    roleTypeRequired: 'Role type is required.',
    saveLabel: 'Save',
    usernameRequired: 'Username is required.',
    emailRequired: 'Email is required. Please provide a valid email address.',
    usersLabel: 'Users',
    rolesLabel: 'Roles',
    addUserLabel: 'Add user',
    addRoleLabel: 'Add role',
    editLabel: 'Edit',
    removeLabel: 'Remove',
    searchLabel: 'Search...',
    typeLabel: 'Type',
    cannotDeleteRoleMessage:
      'Cannot delete the role because some users are assgned to this role.',
    formsLabel: 'Forms',
    categoryLabel: 'Category',
    pdfFileLabel: 'PDF File',
    jsonMetadataLabel: 'JSON Metadata',
    addNewFormLabel: 'Add new form',
    cancelLabel: 'Cancel',
    documentNameLabel: 'Document Name',
    categoryRequired: 'Category is required.',
    documentNameRequired: 'Document name is required.',
    metadatMappingLabel: 'Metadata mapping',
    metadatMappinRequired: 'Metadata mapping is required.',
    assignFormsLabel: 'Assign forms',
    clientsLabel: 'Clients',
    viewLabel: 'View',
    assignedFormsLabel: 'Assigned forms',
    noUserSelectedLabel: 'No user selected',
    submittedLabel: 'Submitted',
    clearSignatureLabel: 'Clear Signature',
    viewPdfLabel: 'View PDF',
    viewMetadataLabel: 'View Metadata',
    comparePdfAndMetadataLabel: 'Compare PDF and Metadata',
    noMetadataAvailableLabel: 'No metadata available.',
    firstNameLabel: 'First Name',
    firstNameRequired: 'First name is required.',
    lastNameLabel: 'Last Name',
    lastNameRequired: 'Last name is required.',
    runtimeLabel: 'Runtime',
    configurationLabel: 'Configuration',
    adminLabel: 'Admin',
    mySettingsLabel: 'My Settings',
    manageUsersLabel: 'Manage Users',
    manageRolesLabel: 'Manage Roles',
    myApiroFormsLabel: 'My APIRO Forms',
    editProfileLabel: 'Edit Profile',
    logoutLabel: 'Logout',
  },
};
