import {
  createStore,
  createSubscriber,
  createHook,
  ActionAny as RSSAction,
  defaultRegistry,
} from 'react-sweet-state';

type FormStoreState = {
  formsList: {}[];
  formsListReady: boolean;
};

type FormStoreActions = {
  setFormsList: (
    formsList: {}[]
  ) => RSSAction<FormStoreState, FormStoreActions>;
};

export const FormStore = createStore<FormStoreState, FormStoreActions>({
  initialState: {
    formsList: [],
    formsListReady: false,
  },
  actions: {
    // eslint-disable-next-line
    setFormsList: (formsList) => ({ setState }) => {
      setState({
        formsList,
        formsListReady: true,
      });
    },
  },
  name: 'form-store',
});

export const FormSubscriber = createSubscriber<
  FormStoreState,
  FormStoreActions
>(FormStore);

export const useForm = createHook<FormStoreState, FormStoreActions>(FormStore);

// static store with no reactivity - just for actions
export const useFormActions = createHook<
  FormStoreState,
  FormStoreActions,
  void
>(FormStore, {
  selector: null,
});

const getFormStore = () =>
  // @ts-ignore calling `getStore` without providing a scopeId
  defaultRegistry.getStore<FormStoreState, FormStoreActions>(UserStore);

// non-reactive static store access without re-render concerns
// @ts-ignore accessing private store property
export const getFormState = () => getFormStore().storeState.getState();
