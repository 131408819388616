import { ROUTE_ACCESS_MAP, ROUTE_VISIBILITY_MAP } from '../constants';

export const isPathAllowedForRole = (path: string, role: string) => {
  if (!path || !role) return false;

  const allowedRoles = ROUTE_ACCESS_MAP[path];

  if (allowedRoles && allowedRoles.length) {
    return allowedRoles.includes(role);
  }

  return false;
};

export const canPathBeVisibleForRole = (path: string, role: string) => {
  if (!path || !role) return false;

  const allowedRoles = ROUTE_VISIBILITY_MAP[path];

  if (allowedRoles && allowedRoles.length) {
    return allowedRoles.includes(role);
  }

  return false;
};
