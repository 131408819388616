import React, { useState } from 'react';
import CustomTable from '../../../../components/CustomTable';
import { useUser } from '../../../../store';
import { LocaleStoreState } from '../../../../store/locale-store';

type UsersListProps = {
  headers: { type: string; key: string; value: string }[];
  searchText: string;
  selectedRole: number;
  locale: LocaleStoreState;
  onItemClick: any;
  data: {}[];
};

const UsersList = ({
  headers,
  searchText,
  selectedRole,
  locale,
  onItemClick,
  data,
}: UsersListProps) => {
  const [user, _] = useUser();

  const [filteredTableData, setFilteredTableData]: any = useState([]);

  React.useEffect(() => {
    if (data) {
      setFilteredTableData([...data]);
    } else {
      setFilteredTableData([...user.usersList]);
    }
  }, [user.usersList, data]); // eslint-disable-line

  // TODO clean up these effects
  React.useEffect(() => {
    if (searchText) {
      searchText = searchText.toLowerCase();
      setFilteredTableData(
        user.usersList.filter(
          (item: any) =>
            item['username'].toLowerCase().includes(searchText) ||
            item['email'].toLowerCase().includes(searchText) ||
            item['role']['name'].toLowerCase().includes(searchText) ||
            (item.first_name &&
              item?.first_name.toLowerCase().includes(searchText)) ||
            (item.last_name &&
              item?.last_name.toLowerCase().includes(searchText))
        )
      );
    } else {
      setFilteredTableData(user.usersList);
    }
  }, [searchText]); // eslint-disable-line

  React.useEffect(() => {
    if (selectedRole != 0) {
      setFilteredTableData(
        user.usersList.filter((item: any) => item['role']['id'] == selectedRole)
      );
    } else {
      setFilteredTableData(user.usersList);
    }
  }, [selectedRole]); // eslint-disable-line

  const headersArray: UsersListProps['headers'] = headers || [
    {
      type: 'text',
      key: 'username',
      value: locale.values?.label?.usernameLabel,
    },
    {
      type: 'text',
      key: 'email',
      value: locale.values?.label?.emailLabel,
    },
    {
      type: 'text',
      key: 'id',
      value: locale.values?.label?.clientIdLabel,
    },
  ];

  return (
    <CustomTable
      headers={headersArray}
      tableData={filteredTableData}
      onItemClick={onItemClick}
    />
  );
};

export default UsersList;
