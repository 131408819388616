import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import styles from './CustomTable.module.scss';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { FaEllipsisH } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#e3e3e3',
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ActionListComponent = (actionListProps: any) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div ref={anchorRef}>
      <Button
        color="primary"
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <FaEllipsisH />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {actionListProps.actions.map((option: any) => (
                    <MenuItem
                      key={option.key}
                      onClick={() => option.callback(actionListProps.data)}
                    >
                      {option.value}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const CustomTable = (props: any) => {
  return (
    <TableContainer component={Paper}>
      <Table className={styles.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {props.headers &&
              props.headers.length > 0 &&
              props.headers.map((headerItem: any, index: number) => (
                <StyledTableCell key={`${headerItem.key}${index}`}>
                  {headerItem.value}
                </StyledTableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData.length === 0 && (
            <StyledTableRow>
              <StyledTableCell align="left">No data</StyledTableCell>
            </StyledTableRow>
          )}
          {props.tableData.map((row: any, rowIndex: number) => (
            <StyledTableRow
              key={row.id}
              onClick={() => (props.onItemClick ? props.onItemClick(row) : '')}
              style={props.onItemClick && { cursor: 'pointer' }}
            >
              {props.headers &&
                props.headers.length > 0 &&
                props.headers.map((headerItem: any, headerItemIndex: number) =>
                  headerItem.type === 'text' ? (
                    <StyledTableCell
                      key={`${rowIndex}${headerItem.key}${headerItemIndex}`}
                      align="left"
                    >
                      {headerItem.subKey
                        ? row[headerItem.key][headerItem.subKey]
                        : row[headerItem.key]}
                    </StyledTableCell>
                  ) : headerItem.type === 'actionText' ? (
                    <StyledTableCell
                      key={`${rowIndex}${headerItem.key}${headerItemIndex}`}
                      align="left"
                    >
                      <NavLink
                        to={`${headerItem.baseUrl}/${
                          row[headerItem.urlParamKey]
                        }`}
                      >
                        {headerItem.subKey
                          ? row[headerItem.key][headerItem.subKey]
                          : row[headerItem.key]}
                      </NavLink>
                    </StyledTableCell>
                  ) : headerItem.type === 'actionsListIcon' ? (
                    <StyledTableCell
                      key={`${rowIndex}${headerItem.key}${headerItemIndex}`}
                      align="left"
                    >
                      <ActionListComponent
                        actions={headerItem.actions}
                        data={row}
                      />
                    </StyledTableCell>
                  ) : headerItem.type === 'actionContainer' ? (
                    <StyledTableCell
                      key={`${rowIndex}${headerItem.key}${headerItemIndex}`}
                      align="left"
                    >
                      <headerItem.actionContainer itemData={row} />
                    </StyledTableCell>
                  ) : headerItem.type === 'container' ? (
                    <StyledTableCell
                      key={`${rowIndex}${headerItem.key}${headerItemIndex}`}
                      align="left"
                    >
                      <headerItem.container itemData={row} />
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell
                      key={`${rowIndex}${headerItem.key}${headerItemIndex}`}
                      align="left"
                    >
                      <div />
                    </StyledTableCell>
                  )
                )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
