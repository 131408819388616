import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LocaleStoreState } from '../../store/locale-store';
import Header from '../../components/Header';
import styles from './Dashboard.module.scss';

type DashboardProps = RouteComponentProps<{}> & {
  locale: LocaleStoreState;
};

function Dashboard(props: DashboardProps) {
  return (
    <React.Fragment>
      <Header locale={props.locale} />
      <div className={styles.pageWrapper}>Dashboard Page</div>
    </React.Fragment>
  );
}

export default Dashboard;
