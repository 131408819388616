import React from 'react';
import { NavLink } from 'react-router-dom';
import { LocaleStoreState } from '../../store/locale-store';
import { useUserActions, getUserState } from '../../store';
import styles from './Header.module.scss';
import { MdMenu } from 'react-icons/md';
import Logo from '../Logo';
import { logout as logoutAPI } from '../../api/user';
import { ROUTES } from '../../constants';
import { canPathBeVisibleForRole } from '../../util/helpers';
import { FaCog } from 'react-icons/fa';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

type HeaderProps = {
  locale: LocaleStoreState;
};

type HeaderNavLinkProps = {
  pathToNavigateTo: string;
  pathToCheckAccess: string;
  role: string;
  children: React.ReactNode;
  exact?: boolean;
  className?: string;
  activeClassName?: string;
};

const NavLinkWithRoleCheck = ({
  pathToNavigateTo,
  pathToCheckAccess,
  role,
  exact = false,
  children,
  className,
  activeClassName,
}: HeaderNavLinkProps) => {
  if (!canPathBeVisibleForRole(pathToCheckAccess, role)) return null;

  return (
    <NavLink
      className={className}
      activeClassName={activeClassName}
      to={pathToNavigateTo}
      exact={exact}
    >
      {children}
    </NavLink>
  );
};
const Header = ({ locale }: HeaderProps) => {
  const [_, userActions] = useUserActions();
  /* static user store data without re-render concerns */
  // TODO: get id from auth instead of iterating through a gazillion users
  const user = getUserState();
  const userId =
    user.usersList[
      user.usersList.findIndex((userData: any) => userData.email === user.email)
    ]?.id;

  const logoutHandler = () => {
    logoutAPI()
      .then(() => {
        userActions.logout();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className={styles.header}>
      <div className={styles.burgerWrapper}>
        <MdMenu />
      </div>
      <div className={styles.logoWrapper}>
        <Logo logoURL={locale.logoURL} />
      </div>
      <nav className={styles.nav}>
        <NavLinkWithRoleCheck
          pathToNavigateTo={ROUTES.DASHBOARD}
          pathToCheckAccess={ROUTES.DASHBOARD}
          role={user.role}
          className={styles.navAnchor}
          activeClassName={styles.navActive}
          exact
        >
          {locale.values?.label?.menuItemDashboard}
        </NavLinkWithRoleCheck>
        <NavLinkWithRoleCheck
          pathToNavigateTo={ROUTES.RUNTIME}
          pathToCheckAccess={ROUTES.RUNTIME}
          role={user.role}
          className={styles.navAnchor}
          activeClassName={styles.navActive}
        >
          {locale.values?.label?.runtimeLabel}
        </NavLinkWithRoleCheck>
        <NavLinkWithRoleCheck
          pathToNavigateTo={ROUTES.CONFIGURATION}
          pathToCheckAccess={ROUTES.CONFIGURATION}
          role={user.role}
          className={styles.navAnchor}
          activeClassName={styles.navActive}
          exact
        >
          {locale.values?.label?.configurationLabel}
        </NavLinkWithRoleCheck>
        <NavLinkWithRoleCheck
          pathToNavigateTo={ROUTES.ADMIN}
          pathToCheckAccess={ROUTES.ADMIN}
          role={user.role}
          className={styles.navAnchor}
          activeClassName={styles.navActive}
          exact
        >
          {locale.values?.label?.adminLabel}
        </NavLinkWithRoleCheck>
        <NavLinkWithRoleCheck
          pathToNavigateTo={ROUTES.APIRO_FORMS}
          pathToCheckAccess={ROUTES.APIRO_FORMS}
          role={user.role}
          className={styles.navAnchor}
          activeClassName={styles.navActive}
          exact
        >
          {locale.values?.label?.apiroFormsLabel}
        </NavLinkWithRoleCheck>
      </nav>
      <div className={styles.userSection}>
        <div
          title={locale.values?.label?.mySettingsLabel}
          onClick={handleClick}
          className={styles.settingsIcon}
        >
          <FaCog />
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <NavLinkWithRoleCheck
              pathToNavigateTo={`${ROUTES.APIRO_FORMS_MANAGE_CLIENTS}/0`}
              pathToCheckAccess={ROUTES.APIRO_FORMS_MANAGE_CLIENTS_WITH_PARAM}
              role={user.role}
              className={styles.settingsMenuNavAnchor}
              exact
            >
              {locale.values?.label?.manageUsersLabel}
            </NavLinkWithRoleCheck>
          </MenuItem>
          <MenuItem>
            <NavLinkWithRoleCheck
              pathToNavigateTo={ROUTES.APIRO_FORMS_MANAGE_ROLES}
              pathToCheckAccess={ROUTES.APIRO_FORMS_MANAGE_ROLES}
              role={user.role}
              className={styles.settingsMenuNavAnchor}
              exact
            >
              {locale.values?.label?.manageRolesLabel}
            </NavLinkWithRoleCheck>
          </MenuItem>
          <MenuItem>
            <NavLinkWithRoleCheck
              pathToNavigateTo={`${ROUTES.APIRO_FORMS_ASSIGN_FORMS}/${userId}`}
              pathToCheckAccess={ROUTES.APIRO_FORMS_ASSIGN_FORMS_WITH_PARAM}
              role={user.role}
              className={styles.settingsMenuNavAnchor}
              exact
            >
              {locale.values?.label?.myApiroFormsLabel}
            </NavLinkWithRoleCheck>
          </MenuItem>
          <MenuItem>
            <NavLinkWithRoleCheck
              pathToNavigateTo={`${ROUTES.APIRO_FORMS_EDIT_CLIENTS}/${userId}`}
              pathToCheckAccess={ROUTES.APIRO_FORMS_EDIT_CLIENTS_WITH_PARAM}
              role={user.role}
              className={styles.settingsMenuNavAnchor}
              exact
            >
              {locale.values?.label?.editProfileLabel}
            </NavLinkWithRoleCheck>
          </MenuItem>
        </Menu>
        <div className={styles.divider}></div>
        <div className={styles.logout} onClick={logoutHandler}>
          {locale.values?.label?.logoutLabel}
        </div>
      </div>
    </header>
  );
};

// hacky memoization
export default React.memo(
  Header,
  (prevProps, nextProps) =>
    prevProps.locale.logoURL === nextProps.locale.logoURL
);
