import { STRAPI } from '../constants';
import { LocaleValuesFromStrapi } from '../types';

/* All feature flags from Strapi */
export type FeatureFlagContent = {
  enableThisCoolThing: boolean;
  enableAnotherCoolThing: boolean;
};

/* All public content from Strapi */
export type PublicContent = {
  logoURL: string;
};

/* All user content from Strapi */
export type UserContent = {
  locale: string;
  values: LocaleValuesFromStrapi;
};

export type RoleContent = {
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
  };
};

export type StrapiUserType = {
  id: number;
  username: string;
  email: string;
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
  };
};

export const fetchPublicContent = async (): Promise<PublicContent> => {
  try {
    const res = await fetch(STRAPI.BASE_URL + STRAPI.INSTANCE_SPACE_PATH);
    const data = await res.json();

    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchUserContent = async (
  _email: string
): Promise<UserContent> => {
  try {
    const res = await fetch(STRAPI.BASE_URL + STRAPI.LOCALES_PATH);
    const data = await res.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchFeatureFlags = async (): Promise<FeatureFlagContent> => {
  try {
    // const res = await fetch(STRAPI.BASE_URL + STRAPI.INSTANCE_SPACE_PATH);
    // const data = await res.json();

    return {} as FeatureFlagContent;
  } catch (e) {
    throw e;
  }
};

export const fetchUserRole = async (
  email: string
): Promise<StrapiUserType | null> => {
  try {
    const res = await fetch(
      `${STRAPI.BASE_URL}${STRAPI.USERS_PATH}?email=${email}`
    );
    const data = (await res.json()) as [StrapiUserType];

    if (data && data.length) {
      return data[0];
    }
    return null;
  } catch (e) {
    throw e;
  }
};

export const addUserRole = async (email: string): Promise<StrapiUserType> => {
  try {
    const payload = {
      username: email,
      email: email,
      password: 'default',
    };

    const res = await fetch(`${STRAPI.BASE_URL}${STRAPI.USERS_PATH}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = (await res.json()) as StrapiUserType;
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchUserRoleOrCreateIfNotFound = async (
  email: string = ''
): Promise<StrapiUserType | null> => {
  if (email) {
    let userRole = await fetchUserRole(email);
    if (!userRole) {
      userRole = await addUserRole(email);
    }
    return userRole;
  }
  return null;
};

export const fetchUsers = async (): Promise<[]> => {
  try {
    const res = await fetch(STRAPI.BASE_URL + STRAPI.USERS_PATH);
    const users = await res.json();
    return users;
  } catch (e) {
    throw e;
  }
};

export const fetchForms = async (): Promise<[]> => {
  try {
    const res = await fetch(STRAPI.BASE_URL + STRAPI.DOC_TEMPLATES_PATH);
    const forms = await res.json();
    return forms;
  } catch (e) {
    throw e;
  }
};

export const fetchRoles = async (): Promise<[]> => {
  try {
    const res = await fetch(STRAPI.BASE_URL + STRAPI.ROLES_PATH);
    const roles = await res.json();
    return roles;
  } catch (e) {
    throw e;
  }
};

export const addEditUser = async (
  isNewUser: boolean,
  userData: any
): Promise<any> => {
  try {
    const payload = {
      username: userData.username,
      email: userData.email,
      first_name: userData.firstName,
      last_name: userData.lastName,
      password: 'default',
      role: userData.role,
      confirmed: true,
      blocked: userData.hasOwnProperty('blocked') ? userData.blocked : false,
      doc_templates: userData.doc_templates,
    };

    let url: string = `${STRAPI.BASE_URL}${STRAPI.USERS_PATH}`;

    if (!isNewUser) {
      url += `/${userData.id}`;
    }

    const res = await fetch(url, {
      method: isNewUser ? 'POST' : 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchUserDetails = async (_id: number): Promise<UserContent> => {
  try {
    const res = await fetch(`${STRAPI.BASE_URL}${STRAPI.USERS_PATH}/${_id}`);
    const userData = await res.json();
    return userData;
  } catch (e) {
    throw e;
  }
};

export const deleteUser = async (_id: number): Promise<UserContent> => {
  try {
    const res = await fetch(`${STRAPI.BASE_URL}${STRAPI.USERS_PATH}/${_id}`);
    const userData = await res.json();
    userData.blocked = true;
    return addEditUser(false, userData);
  } catch (e) {
    throw e;
  }
};

export const fetchRoleDetails = async (_id: number): Promise<RoleContent> => {
  try {
    const res = await fetch(`${STRAPI.BASE_URL}${STRAPI.ROLES_PATH}/${_id}`);
    const roleData = await res.json();
    return roleData;
  } catch (e) {
    throw e;
  }
};

export const addEditRole = async (
  isNewRole: boolean,
  roleData: any
): Promise<any> => {
  try {
    const payload = {
      name: roleData.name,
      description: roleData.description,
      type: roleData.type,
    };

    let url: string = `${STRAPI.BASE_URL}${STRAPI.ROLES_PATH}`;

    if (!isNewRole) {
      url += `/${roleData.id}`;
    }

    const res = await fetch(url, {
      method: isNewRole ? 'POST' : 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const uploadProfileImage = async (
  image: File,
  userId: any
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('files', image);
    formData.append('ref', 'user');
    formData.append('refId', userId);
    formData.append('source', 'users-permissions');
    formData.append('field', 'profile_image');

    const res = await fetch(`${STRAPI.BASE_URL}/upload`, {
      method: 'POST',
      body: formData,
    });
    const data = await res.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const deleteRole = async (_id: number): Promise<any> => {
  try {
    const res = await fetch(`${STRAPI.BASE_URL}${STRAPI.ROLES_PATH}/${_id}`, {
      method: 'DELETE',
    });
    const data = await res.json();
    return data;
  } catch (e) {
    throw e;
  }
};

export const addNewForm = async (
  doc_name: string,
  doc_category: string,
  doc_template: File,
  metadata_mapping: string
): Promise<any> => {
  try {
    const data = {
      doc_name,
      doc_category,
      metadata_mapping,
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('files.doc_template', doc_template);

    const res = await fetch(`${STRAPI.BASE_URL}${STRAPI.DOC_TEMPLATES_PATH}`, {
      method: 'POST',
      body: formData,
    });
    const responseData = await res.json();
    return responseData;
  } catch (e) {
    throw e;
  }
};

export const assignForms = async (
  _id: number,
  docs: []
): Promise<UserContent> => {
  try {
    const res = await fetch(`${STRAPI.BASE_URL}${STRAPI.USERS_PATH}/${_id}`);
    const userData = await res.json();
    userData.doc_templates = docs;
    return addEditUser(false, userData);
  } catch (e) {
    throw e;
  }
};

export const fetchOneForm = async (_id: number): Promise<[]> => {
  try {
    const res = await fetch(
      `${STRAPI.BASE_URL}${STRAPI.DOC_TEMPLATES_PATH}/${_id}`
    );
    const forms = await res.json();
    return forms;
  } catch (e) {
    throw e;
  }
};
