import React from 'react';
import { merge } from 'lodash';
import { useLocale, useUser } from '../../store';
import { fetchPublicContent, fetchUserContent } from '../../api/strapi';
import { STRAPI } from '../../constants';
import { defaultUserLocale, defaultPublicLocale } from './defaults';

/* Strapi data available without user login */
const composePublicContent = (data: any) => {
  const logoURLPath = data?.Style?.logo512_png?.formats?.thumbnail?.url;
  const logoURL = logoURLPath
    ? STRAPI.BASE_URL + logoURLPath
    : defaultPublicLocale.Style.logo512_png.formats.thumbnail.url;

  return { logoURL };
};

/* Strapi data that should be available only after user login. But it's all public right now. */
const composeUserContent = (data: any) => {
  const values = merge(defaultUserLocale, {
    label: data.values.label,
  });
  return { locale: 'en', values };
};

function LocaleProvider({ children }: { children: React.ReactNode }) {
  const [locale, localeActions] = useLocale();
  const [user, _] = useUser();

  React.useEffect(() => {
    fetchPublicContent().then((data) => {
      localeActions.setPublicContent(composePublicContent(data));
    });
  }, [localeActions]);

  React.useEffect(() => {
    if (user.isAuthenticated) {
      fetchUserContent(user.email).then((data) => {
        localeActions.setUserContent(composeUserContent(data));
      });
    }
  }, [user.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!locale.publicContentReady) return null;

  return <>{children}</>;
}
export default LocaleProvider;
