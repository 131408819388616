import React from 'react';
import ReactDOM from 'react-dom';
import { defaults } from 'react-sweet-state';
// eslint-disable-next-line
import * as firebase from 'firebase/app';
import 'firebase/auth';

import App from './App';
import AppProviders from './AppProviders';
import { firebaseConfig } from './util/config';
import './styles/global.scss';

if (process.env.NODE_ENV === 'development') {
  defaults.devtools = true;
}
Object.keys(firebaseConfig).forEach((key) => {
  // @ts-ignore
  if (!firebaseConfig[key])
    throw Error(`ENVIRONMENT VARIABLE MISSING - Firebase config ${key}`);
});

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root')
);
