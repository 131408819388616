import React from 'react';
import { merge } from 'lodash';
import { useFeatureFlag } from '../../store';
import { fetchFeatureFlags } from '../../api/strapi';
import { defaultFeatureFlags } from './defaults';

function FeatureFlagProvider({ children }: { children: React.ReactNode }) {
  const [_, featureFlagActions] = useFeatureFlag();

  React.useEffect(() => {
    fetchFeatureFlags().then((data) => {
      const featureFlags = merge(defaultFeatureFlags, data);
      featureFlagActions.setFeatureFlags(featureFlags);
    });
  }, [featureFlagActions]);

  return <>{children}</>;
}
export default FeatureFlagProvider;
