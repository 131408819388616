import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { useUser } from '../../store';
import {
  login as loginAPI,
  loginWithGoogle as loginWithGoogleAPI,
} from '../../api/user';
import { LocaleStoreState } from '../../store/locale-store';
import { ROUTES } from '../../constants';

import styles from './Login.module.scss';
import Logo from '../../components/Logo/index';
import { FaGoogle } from 'react-icons/fa';

const defaultEmail =
  process.env.NODE_ENV === 'development' ? 'tryout001@yopmail.com' : '';
const defaultPassword = process.env.NODE_ENV === 'development' ? '111111' : '';

type LoginProps = {
  locale: LocaleStoreState;
};

const Login = (props: LoginProps) => {
  const [userState, userActions] = useUser();
  const [email, setEmail] = React.useState(defaultEmail);
  const [password, setPassword] = React.useState(defaultPassword);
  const [responseMessage, setResponseMessage] = React.useState('');

  const onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const onPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const loginHandler = () => {
    loginAPI(email, password)
      .then((data) => {
        if (data) {
          const { user, role } = data;
          if (user) {
            const email = user.email as string;
            user.getIdToken().then((idToken) => {
              userActions.authenticate(email, user, idToken, role);
            });
          }
        }
      })
      .catch((e) => {
        setResponseMessage(e.message);
      });
  };

  const signinWithGoogleHandler = () => {
    loginWithGoogleAPI()
      .then((data) => {
        if (data) {
          const { user, role } = data;
          if (user) {
            const email = user.email as string;
            user.getIdToken().then((idToken) => {
              userActions.authenticate(email, user, idToken, role);
            });
          }
        }
      })
      .catch((e) => {
        setResponseMessage(e.message);
      });
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      loginHandler();
    }
  };

  if (userState.isAuthenticated) return <Redirect to={ROUTES.ROOT} />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoContainer}>
        <Logo logoURL={props.locale.logoURL} />
      </div>
      {responseMessage && (
        <div className={styles.notificationBox}>
          <p>{responseMessage}</p>
        </div>
      )}
      <input type="text" placeholder="Email" onChange={onEmailChange}></input>
      <input
        type="password"
        placeholder="Password"
        onChange={onPasswordChange}
        onKeyDown={keyDownHandler}
      ></input>
      <button className={styles.loginBtn} onClick={loginHandler}>
        Login
      </button>
      <NavLink to={ROUTES.RESET_PASSWORD} exact>
        <button
          className={`${styles.loginBtn} ${styles.textBtn}`}
          onClick={loginHandler}
        >
          Forgot your password?
        </button>
      </NavLink>
      <button
        className={`${styles.loginBtn} ${styles.googleLoginBtn}`}
        onClick={signinWithGoogleHandler}
      >
        <span className={styles.googleIconWrapper}>
          <FaGoogle className={styles.googleIcon} size={24} />
        </span>
        Signin with Google
      </button>
      <NavLink to={ROUTES.PRIVACY} exact>
        <button className={`${styles.loginBtn} ${styles.textBtn}`}>
          <span className={styles.greyText}>
            By signing in, you agree to APIRO's
          </span>{' '}
          <span className={styles.textBtn}>Terms of Use</span>{' '}
          <span className={styles.greyText}>and</span>{' '}
          <span className={styles.textBtn}>Privacy Policy</span>.
        </button>
      </NavLink>
      <NavLink to={ROUTES.SIGNUP} exact>
        <button className={`${styles.loginBtn} ${styles.textBtn}`}>
          <span className={styles.greyText}>Not a member yet? Click</span>{' '}
          <span className={styles.textBtn}>here</span>{' '}
          <span className={styles.greyText}>to register.</span>
        </button>
      </NavLink>
    </div>
  );
};

export default Login;
