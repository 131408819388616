import React from 'react';
import { NavLink } from 'react-router-dom';
import { resetPassword as resetPasswordAPI } from '../../api/user';
import { ROUTES } from '../../constants';
import styles from './PasswordReset.module.scss';

const PasswordReset = () => {
  const [email, setEmail] = React.useState('');
  const [resetEmailSent, setResetEmailSent] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState('');

  const onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const passwordResetHandler = () => {
    resetPasswordAPI(email)
      .then((_) => {
        setResetEmailSent(true);
      })
      .catch((e) => {
        setResponseMessage(e.message);
      });
  };

  return (
    <div className={styles.wrapper}>
      <input type="text" onChange={onEmailChange} placeholder="Email"></input>

      <button className={styles.cta} onClick={passwordResetHandler}>
        Send password reset email
      </button>

      <NavLink className={styles.resetLink} to={ROUTES.ROOT} exact>
        <button className={styles.cta}>Go back and try again</button>
      </NavLink>
      {resetEmailSent ? (
        <p>
          Password reset email has been sent to {email}. Please check your
          inbox.
        </p>
      ) : (
        <p>{responseMessage}</p>
      )}
    </div>
  );
};

export default PasswordReset;
