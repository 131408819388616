import React from 'react';
import { useUser, useForm } from './store';
import { getStaticLocaleState } from './store/locale-store';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { fetchUsers, fetchRoles, fetchForms } from './api/strapi';
import { ROUTES } from './constants';

import PrivateRoute from './components/PrivateRoute';
import LoadingScreen from './components/LoadingScreen';
import Login from './pages/Login';
import PasswordReset from './pages/PasswordReset';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Runtime from './pages/Runtime';
import Configuration from './pages/Configuration';
import Admin from './pages/Admin';
import ApiroForms from './pages/ApiroForms';

function App() {
  const [user, userActions] = useUser();
  const locale = getStaticLocaleState();
  const [form, formActions] = useForm();

  React.useEffect(() => {
    if (user.isAuthenticated) {
      fetchRoles().then((data: any) => {
        userActions.setApplicationsRolesList(data.roles);
      });
      fetchUsers().then((data: any) => {
        userActions.setUsersList([...data]);
      });
      fetchForms().then((data: any) => {
        formActions.setFormsList([...data]);
      });
    }
  }, [user.isAuthenticated]); // eslint-disable-line

  if (!locale.publicContentReady || !user.authCheckDone)
    return <LoadingScreen />;

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={ROUTES.LOGIN}
          render={(props: any) => <Login locale={locale} {...props} />}
        />
        <Route exact path={ROUTES.SIGNUP} component={Signup} />
        <Route exact path={ROUTES.RESET_PASSWORD} component={PasswordReset} />
        <Route
          exact
          path="/"
          render={() => {
            return user.isAuthenticated ? (
              <Redirect to={`${ROUTES.RUNTIME}/raw`} />
            ) : (
              <Redirect to={ROUTES.LOGIN} />
            );
          }}
        />
        <Route
          exact
          path={ROUTES.RUNTIME}
          render={() => <Redirect to={`${ROUTES.RUNTIME}/raw`} />}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          role={user.role}
          exact
          path={ROUTES.DASHBOARD}
          render={(props: any) =>
            locale.userContentReady ? (
              <Dashboard locale={locale} {...props} />
            ) : (
              <LoadingScreen />
            )
          }
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          role={user.role}
          path={ROUTES.RUNTIME_WITH_PARAM}
          render={(props: any) =>
            locale.userContentReady ? (
              <Runtime locale={locale} {...props} />
            ) : (
              <LoadingScreen />
            )
          }
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          role={user.role}
          exact
          path={ROUTES.CONFIGURATION}
          render={(props: any) =>
            locale.userContentReady ? (
              <Configuration locale={locale} {...props} />
            ) : (
              <LoadingScreen />
            )
          }
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          role={user.role}
          exact
          path={ROUTES.ADMIN}
          render={(props: any) =>
            locale.userContentReady ? (
              <Admin locale={locale} {...props} />
            ) : (
              <LoadingScreen />
            )
          }
        />
        <Route
          path="/apiroforms/"
          render={(props: any) =>
            user.applicationRolesListReady &&
            user.usersListReady &&
            form.formsListReady ? (
              <ApiroForms locale={locale} {...props} />
            ) : (
              <LoadingScreen />
            )
          }
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
