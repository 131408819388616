import React, { useState } from 'react';
import styles from './RolesAddEdit.module.scss';
import {
  fetchRoleDetails,
  addEditRole,
  fetchRoles,
} from '../../../../api/strapi';
import { useHistory } from 'react-router-dom';
import CustomInput from '../../../../components/CustomInput';
import { useUser } from '../../../../store';
import { LocaleStoreState } from '../../../../store/locale-store';

type RolesAddEditProps = {
  locale: LocaleStoreState;
  match: any;
};

const RolesAddEdit = ({ locale, match }: RolesAddEditProps) => {
  const history: any = useHistory();

  const [user, userActions] = useUser();

  const [name, setName] = useState('');
  const [isNameValid, setIsNameValid]: any = useState(null);
  const [description, setDescription] = useState('');
  const [isDescriptionValid, setIsDescriptionValid]: any = useState(null);
  const [type, setType] = useState('');
  const [isTypeValid, setIsTypeValid]: any = useState(null);

  React.useEffect(() => {
    if (match.params.id) {
      let index: any = user.applicationRolesList.findIndex(
        (item: any) => item.id == match.params.id
      );
      let roleData: any = user.applicationRolesList[index];
      if (roleData) {
        onNameChange(roleData?.name);
        onDescriptionChange(roleData?.description);
        onTypeChange(roleData?.type);
      }
    }
  }, []); // eslint-disable-line

  const onNameChange = (name: string) => {
    setIsNameValid(!!name);
    setName(name);
  };

  const onDescriptionChange = (description: string) => {
    setIsDescriptionValid(!!description);
    setDescription(description);
  };

  const onTypeChange = (type: string) => {
    setIsTypeValid(!!type);
    setType(type);
  };

  const handleAddOrEditRole = () => {
    if (
      (isNameValid ?? isDescriptionValid ?? isTypeValid) &&
      isNameValid &&
      isDescriptionValid &&
      isTypeValid
    ) {
      addEditRole(match.params.id ? false : true, {
        id: match.params.id,
        name,
        description,
        type,
      }).then(() => {
        fetchRoles().then((data: any) => {
          userActions.setApplicationsRolesList(data.roles);
        });
        history.push(`/apiroforms/roles/manage`);
      });
    } else {
      onNameChange(name);
      onDescriptionChange(description);
      onTypeChange(type);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <CustomInput
          required={true}
          label={locale.values?.label?.nameLabel}
          value={name}
          onChange={(e: any) => onNameChange(e.target.value)}
          placeholder={locale.values?.label?.nameLabel}
          errorMessage={
            isNameValid != null && isNameValid != undefined
              ? isNameValid
                ? ''
                : locale.values?.label?.roleNameRequired
              : ''
          }
        />
      </div>
      <div>
        <CustomInput
          required={true}
          label={locale.values?.label?.descriptionLabel}
          value={description}
          onChange={(e: any) => onDescriptionChange(e.target.value)}
          placeholder={locale.values?.label?.descriptionLabel}
          errorMessage={
            isDescriptionValid != null && isDescriptionValid != undefined
              ? isDescriptionValid
                ? ''
                : locale.values?.label?.roleDescriptionRequired
              : ''
          }
        />
      </div>
      <div>
        <CustomInput
          required={true}
          label={locale.values?.label?.typeLabel}
          value={type}
          onChange={(e: any) => onTypeChange(e.target.value)}
          placeholder={locale.values?.label?.typeLabel}
          errorMessage={
            isTypeValid != null && isTypeValid != undefined
              ? isTypeValid
                ? ''
                : locale.values?.label?.roleTypeRequired
              : ''
          }
        />
      </div>
      <div>
        <button className={styles.saveBtn} onClick={handleAddOrEditRole}>
          {locale.values?.label?.saveLabel}
        </button>
      </div>
    </div>
  );
};

export default RolesAddEdit;
