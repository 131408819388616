import {
  createStore,
  createSubscriber,
  createHook,
  ActionAny as RSSAction,
  defaultRegistry,
} from 'react-sweet-state';
import firebase from 'firebase';

type UserStoreState = {
  isAuthenticated: boolean;
  authCheckDone: boolean;
  email: string;
  role: string;
  firebaseUser: firebase.User | null;
  idToken: string;
  applicationRolesList: {
    id: number;
    name: string;
    description: string;
    type: string;
  }[];
  applicationRolesListReady: boolean;
  usersList: { id: number }[];
  usersListReady: boolean;
};

type UserStoreActions = {
  authenticate: (
    email: string,
    firebaseUser: firebase.User,
    idToken: string,
    role: string
  ) => RSSAction<UserStoreState, UserStoreActions>;
  setAuthCheckDone: (
    status: boolean
  ) => RSSAction<UserStoreState, UserStoreActions>;
  logout: () => RSSAction<UserStoreState, UserStoreActions>;
  setApplicationsRolesList: (
    applicationRolesList: {
      id: any;
      name: string;
      description: string;
      type: string;
    }[]
  ) => RSSAction<UserStoreState, UserStoreActions>;
  setUsersList: (
    usersList: { id: number }[]
  ) => RSSAction<UserStoreState, UserStoreActions>;
};

export const UserStore = createStore<UserStoreState, UserStoreActions>({
  initialState: {
    isAuthenticated: false,
    authCheckDone: false,
    email: '',
    role: '',
    firebaseUser: null,
    idToken: '',
    applicationRolesList: [],
    applicationRolesListReady: false,
    usersList: [],
    usersListReady: false,
  },
  actions: {
    // eslint-disable-next-line
    authenticate: (email, firebaseUser, idToken, role) => ({ setState }) => {
      setState({
        isAuthenticated: true,
        authCheckDone: true,
        email: email,
        role: role,
        idToken: idToken,
        firebaseUser: firebaseUser,
      });
    },
    setAuthCheckDone: (status = true) => ({ setState }) => {
      setState({
        authCheckDone: status,
      });
    },
    logout: () => ({ setState }) => {
      setState({
        isAuthenticated: false,
        email: '',
        role: '',
      });
    },
    setApplicationsRolesList: (applicationRolesList) => ({ setState }) => {
      setState({
        applicationRolesList,
        applicationRolesListReady: true,
      });
    },
    setUsersList: (usersList) => ({ setState }) => {
      setState({
        usersList,
        usersListReady: true,
      });
    },
  },
  name: 'user-store',
});

export const UserSubscriber = createSubscriber<
  UserStoreState,
  UserStoreActions
>(UserStore);

export const useUser = createHook<UserStoreState, UserStoreActions>(UserStore);

// static store with no reactivity - just for actions
export const useUserActions = createHook<
  UserStoreState,
  UserStoreActions,
  void
>(UserStore, {
  selector: null,
});

const getUserStore = () =>
  // @ts-ignore calling `getStore` without providing a scopeId
  defaultRegistry.getStore<UserStoreState, UserStoreActions>(UserStore);

// non-reactive static store access without re-render concerns
// @ts-ignore accessing private store property
export const getUserState = () => getUserStore().storeState.getState();
