import React from 'react';
import styles from './CustomInput.module.scss';
import TextField from '@material-ui/core/TextField';

const CustomInput = (props: any) => (
  <TextField
    required={props.required}
    error={!!props.errorMessage}
    label={props.label}
    value={props.value}
    onChange={props.onChange}
    className={styles.input}
    placeholder={props.placeholder}
    helperText={props.errorMessage}
    fullWidth
    margin="normal"
    InputLabelProps={{
      shrink: true,
    }}
    variant="outlined"
    size="small"
    multiline={props.multiline || false}
    rows={props.rows || 1}
  />
);

export default CustomInput;
