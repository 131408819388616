import React, { useState } from 'react';
import styles from './PdfAndJsonView.module.scss';
import { LocaleStoreState } from '../../../../store/locale-store';
import { useForm } from '../../../../store';
import { STRAPI } from '../../../../constants';

type PdfAndJsonViewProps = {
  locale: LocaleStoreState;
  match: any;
};

const PdfView = (props: any) => {
  const pdfURL = props.formData && props.formData.doc_template.url;
  if (!pdfURL) return null;

  const fullURL = `${STRAPI.BASE_URL}${pdfURL}`;

  return (
    <div className={styles.pdfcontainer}>
      <iframe
        className={styles.responsiveIframe}
        title={'PDF'}
        src={fullURL}
        width="800px"
        height="500px"
      ></iframe>
    </div>
  );
};

const JsonView = (props: any) => {
  return (
    <div className={styles.jsonContainer}>
      {props.formData?.metadata_mapping
        ? JSON.stringify(props.formData?.metadata_mapping)
        : props.locale.values?.label?.noMetadataAvailableLabel}
    </div>
  );
};

const PdfAndJsonView = ({ locale, match }: PdfAndJsonViewProps) => {
  const [formData, setFormData]: any = useState(null);
  const [form, _]: any = useForm();

  React.useEffect(() => {
    let formIndex: number = form.formsList.findIndex(
      (formItem: any) => formItem.id == match.params.formId
    );

    setFormData({ ...form.formsList[formIndex] });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.mainContainer}>
      <h3>{formData?.doc_name}</h3>
      {match.params.type === 'pdf' ? (
        <PdfView formData={formData} locale={locale} />
      ) : match.params.type === 'json' ? (
        <JsonView formData={formData} locale={locale} />
      ) : (
        <div className={styles.pdfAndJsonContainer}>
          <PdfView formData={formData} locale={locale} />
          <JsonView formData={formData} locale={locale} />
        </div>
      )}
    </div>
  );
};

export default PdfAndJsonView;
