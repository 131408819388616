export const mockRatesRes = {
  rates: {
    aggregated_rates: [],
    historical_rates: [],
    raw_rates: [
      {
        schemas: [
          { name: 'COMPANY_RATIOS' },
          {
            name: 'COMPANY_INCOME_STATEMENT',
            rates: [
              {
                SYMBOL: 'ITEC',
                MARKET: 'NSEI',
                status: 'violated',
                blocking: 'true',
                attributes: [
                  {
                    'STOCK NAME': 'ITC Limited',
                    metadata: { DISPLAY_NAME: 'Stock', Level: 'NA' },
                  },
                  {
                    DATE_RE: 2019,
                    metadata: {
                      DISPLAY_NAME: 'Date Reported/Reinstated',
                      Level: 'NA',
                    },
                  },
                  {
                    DATE_SOURCED: '25/8/2020',
                    metadata: {
                      DISPLAY_NAME: 'DATE_SOURCED',
                      Level: 'NA',
                    },
                  },
                  {
                    TOTAL_REV: '478,390,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Revenue',
                      Level: '1',
                    },
                    status: 'violated',
                    blocking: 'true',
                  },
                  {
                    BUSINESS_REV: '478,454,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Business Revenue',
                      Level: '1_1',
                    },
                  },
                  {
                    EXCISE_TAX: '(64,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Excise Taxes',
                      Level: '1_2',
                    },
                  },
                  {
                    O_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Revenue',
                      Level: '1_3',
                    },
                  },
                  {
                    COST_OF_REV: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Revenue',
                      Level: '2',
                    },
                  },
                  {
                    COGS: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Goods and Services',
                      Level: '2_1',
                    },
                  },
                  {
                    CHG_INVENT: 0,
                    metadata: {
                      DISPLAY_NAME: 'Changes in Inventories',
                      Level: '2_2',
                    },
                  },
                  {
                    O_COST_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Cost of Revenue',
                      Level: '2_3',
                    },
                  },
                  {
                    GRO_PRO: '304,186,700,000',
                    metadata: { DISPLAY_NAME: 'Gross Profit', Level: '3' },
                  },
                  {
                    'OPR_INC/EXP': '(132,074,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operating Income/Expenses',
                      Level: '3',
                    },
                  },
                  {
                    SELL_GEN_ADM: '(63,325,600,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Selling, General and Administrative Expenses',
                      Level: '3_1',
                    },
                  },
                  {
                    STAFF_COSTS: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Staff Costs',
                      Level: '3_1_1',
                    },
                  },
                  {
                    O_STAFF_COST: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Staff Costs',
                      Level: '3_1_1_1',
                    },
                  },
                  {
                    GEN_ADM_EXP: '(193,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'General and Administrative Expenses',
                      Level: '3_1_2',
                    },
                  },
                  {
                    LEG_ACC_FEES: '(4,554,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Legal, Accounting, Auditing and Consulting Fees',
                      Level: '3_1_3',
                    },
                  },
                  {
                    LEG_FEES: '(500,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Legal Fees',
                      Level: '3_1_3_1',
                    },
                  },
                  {
                    O_FEES: '(4,054,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Other Professional Accounting and Consulting Fees',
                      Level: '3_1_3_2',
                    },
                  },
                  {
                    TEL_EXP: '(328,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Telecommunication Expense',
                      Level: '3_1_4',
                    },
                  },
                  {
                    INS_CLAIMS: '(843,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Insurance and Claims',
                      Level: '3_1_5',
                    },
                  },
                  {
                    RENT_EXP: '(3,378,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Rent Expense',
                      Level: '3_1_6',
                    },
                  },
                  {
                    SELL_MKT_EXP: '(12,001,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Selling and Marketing Expenses',
                      Level: '3_1_7',
                    },
                  },
                  {
                    OPRI_EXP: '(6,503,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operation and Maintenance Expenses',
                      Level: '3_2',
                    },
                  },
                  {
                    RCH_DEV_EXP: '(571,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Research and Development Expenses',
                      Level: '3_3',
                    },
                  },
                  {
                    DEP_AMO_DEP: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation, Amortization and Depletion',
                      Level: '3_4',
                    },
                  },
                  {
                    DEP_AMOR: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation and Amortization',
                      Level: '3_4_1',
                    },
                  },
                  {
                    DEP: '(13,480,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation',
                      Level: '3_4_1_1',
                    },
                  },
                  {
                    AMOR: '(485,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Amortization',
                      Level: '3_4_1_2',
                    },
                  },
                  {
                    TAX_OTH: '(2,635,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Taxes Other Than Income Taxes',
                      Level: '3_5',
                    },
                  },
                  {
                    PRV_EXP: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision Expense/Write-Back',
                      Level: '3_6',
                    },
                  },
                  {
                    PRV_DOUBT: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Doubtful Accounts',
                      Level: '3_6_1',
                    },
                  },
                  {
                    O_PRV_EXP: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Provision Expense/Write-Back',
                      Level: '3_6_2',
                    },
                  },
                  {
                    O_INEX_OPR: '(44,686,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Operating',
                      Level: '3_7',
                    },
                  },
                  {
                    O_INC_OPR: '5,841,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income, Operating',
                      Level: '3_7_1',
                    },
                  },
                  {
                    O_EXP_OPR: '(50,527,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Expenses, Operating',
                      Level: '3_7_2',
                    },
                  },
                  {
                    T_OPR_PnL: '172,112,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Operating Profit/Loss',
                      Level: '3',
                    },
                  },
                  {
                    NOPR_INEX: '19,385,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Non-Operating Income/Expenses, Total',
                      Level: '4',
                    },
                  },
                  {
                    T_NETFIN_INEX: '12,413,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Net Finance Income/Expense',
                      Level: '4_1',
                    },
                  },
                  {
                    NET_INT_INEX: '12,673,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Interest Income/Expense',
                      Level: '4_1_1',
                    },
                  },
                  {
                    INT_EXP_NCAP: '(454,200,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Interest Expense Net of Capitalized Interest',
                      Level: '4_1_1_1',
                    },
                  },
                  {
                    INT_INC: '13,127,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Interest Income',
                      Level: '4_1_1_2',
                    },
                  },
                  {
                    O_FIN_INEX: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Income/Expenses',
                      Level: '4_1_1_3',
                    },
                  },
                  {
                    O_FIN_EXP: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Expenses',
                      Level: '4_1_1_3_1',
                    },
                  },
                  {
                    NET_INV_INC: '7,669,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Investment Income',
                      Level: '4_2',
                    },
                  },
                  {
                    DIV_INV_INC: '83,800,000',
                    metadata: {
                      DISPLAY_NAME: 'Dividend and Investment Income',
                      Level: '4_2_1',
                    },
                  },
                  {
                    FV_UNREA_GL: '7,538,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Fair Value or Unrealized Gain/Loss on Financial Assets',
                      Level: '4_2_2',
                    },
                  },
                  {
                    INC_ASS_JV: '117,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Income from Associates, Joint Ventures and Other Participating Interests',
                      Level: '4_2_3',
                    },
                  },
                  {
                    SHARE_JV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Share of Profit and Interest from Joint Ventures',
                      Level: '4_2_3_1',
                    },
                  },
                  {
                    GL_LO_ON_FX: '(69,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Foreign Exchange',
                      Level: '4_2_4',
                    },
                  },
                  {
                    GL_ON_INV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Gain/Loss on Investments and Other Financial Instruments',
                      Level: '4_1_5',
                    },
                  },
                  {
                    GL_ON_DER: 0,
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Derivatives',
                      Level: '4_1_6',
                    },
                  },
                  {
                    IRR_INEX: '(956,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Irregular Income/Expenses',
                      Level: '4_3',
                    },
                  },
                  {
                    DISP_OF_BUS: '94,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Disposal of Businesses',
                      Level: '4_3_1',
                    },
                  },
                  {
                    AST_DISP: '(1,050,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Asset Disposals',
                      Level: '4_3_2',
                    },
                  },
                  {
                    IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Impairment/Write Off/Write Down of Other Assets',
                      Level: '4_3_3',
                    },
                  },
                  {
                    OTH_IRR_INC: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Irregular Income/Expenses',
                      Level: '4_3_4',
                    },
                  },
                  {
                    GDW_IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME: 'Goodwill Impairment/Write Off',
                      Level: '4_3_5',
                    },
                  },
                  {
                    O_INEX_NOPR: '259,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Non-Operating',
                      Level: '4_4',
                    },
                  },
                  {
                    PRETAX_INC: '191,498,200,000',
                    metadata: {
                      DISPLAY_NAME: 'Pretax Income',
                      Level: '5',
                    },
                  },
                  {
                    PRV_INC_TAX: '(63,139,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Income Tax',
                      Level: '5',
                    },
                  },
                  {
                    CUR_TAX: '(61,916,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Current Tax',
                      Level: '5_1',
                    },
                  },
                  {
                    DEF_TAX: '(1,223,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Deferred Tax',
                      Level: '5_2',
                    },
                  },
                  {
                    NETINC_COPS: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Income from Continuing Operations',
                      Level: '6',
                    },
                  },
                  {
                    NETINC_DISC: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Extraordinary Items and Discontinued Operations',
                      Level: '7',
                    },
                  },
                  {
                    NON_CON_MIN: '(2,435,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Non-Controlling/Minority Interests',
                      Level: '8',
                    },
                  },
                  {
                    NETINC_A_MIN: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Non-Controlling/Minority Interests',
                      Level: '9',
                    },
                  },
                  {
                    NETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income Available to Common Stockholders',
                      Level: '10',
                    },
                  },
                  {
                    DNETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Diluted Net Income Available to Common Stockholders',
                      Level: '11',
                    },
                  },
                  {
                    EAR_EQ_PTAX: 0,
                    metadata: {
                      DISPLAY_NAME: 'Earnings from Equity Interest, Post-Tax',
                      Level: '12',
                    },
                  },
                  {
                    OTH_ADJ_NETINC_SH: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Other Adjustments to Net Income Available to Common Stockholders',
                      Level: '13',
                    },
                  },
                ],
                properties: [
                  {
                    blg_id: 'ksdoglwsdfgr',
                    ric_code: 'abc',
                    factset_id: '786532',
                  },
                ],
                medadata: [
                  {
                    statement: 'reported',
                    audited: 'true',
                    reported_date: '31/03/2019',
                  },
                ],
              },
              {
                SYMBOL: 'AMZN',
                MARKET: 'NASDAQ',
                status: 'violated',
                blocking: 'false',
                attributes: [
                  {
                    'STOCK NAME': 'Amazon.com, Inc.',
                    metadata: { DISPLAY_NAME: 'Stock', Level: 'NA' },
                  },
                  {
                    DATE_RE: 2019,
                    metadata: {
                      DISPLAY_NAME: 'Date Reported/Reinstated',
                      Level: 'NA',
                    },
                  },
                  {
                    DATE_SOURCED: '25/8/2020',
                    metadata: {
                      DISPLAY_NAME: 'DATE_SOURCED',
                      Level: 'NA',
                    },
                  },
                  {
                    TOTAL_REV: '478,390,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Revenue',
                      Level: '1',
                    },
                  },
                  {
                    BUSINESS_REV: '478,454,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Business Revenue',
                      Level: '1_1',
                    },
                    status: 'violated',
                    blocking: 'false',
                  },
                  {
                    EXCISE_TAX: '(64,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Excise Taxes',
                      Level: '1_2',
                    },
                  },
                  {
                    O_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Revenue',
                      Level: '1_3',
                    },
                  },
                  {
                    COST_OF_REV: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Revenue',
                      Level: '2',
                    },
                  },
                  {
                    COGS: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Goods and Services',
                      Level: '2_1',
                    },
                  },
                  {
                    CHG_INVENT: 0,
                    metadata: {
                      DISPLAY_NAME: 'Changes in Inventories',
                      Level: '2_2',
                    },
                  },
                  {
                    O_COST_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Cost of Revenue',
                      Level: '2_3',
                    },
                  },
                  {
                    GRO_PRO: '304,186,700,000',
                    metadata: { DISPLAY_NAME: 'Gross Profit', Level: '3' },
                  },
                  {
                    'OPR_INC/EXP': '(132,074,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operating Income/Expenses',
                      Level: '3',
                    },
                  },
                  {
                    SELL_GEN_ADM: '(63,325,600,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Selling, General and Administrative Expenses',
                      Level: '3_1',
                    },
                  },
                  {
                    STAFF_COSTS: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Staff Costs',
                      Level: '3_1_1',
                    },
                  },
                  {
                    O_STAFF_COST: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Staff Costs',
                      Level: '3_1_1_1',
                    },
                  },
                  {
                    GEN_ADM_EXP: '(193,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'General and Administrative Expenses',
                      Level: '3_1_2',
                    },
                  },
                  {
                    LEG_ACC_FEES: '(4,554,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Legal, Accounting, Auditing and Consulting Fees',
                      Level: '3_1_3',
                    },
                  },
                  {
                    LEG_FEES: '(500,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Legal Fees',
                      Level: '3_1_3_1',
                    },
                  },
                  {
                    O_FEES: '(4,054,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Other Professional Accounting and Consulting Fees',
                      Level: '3_1_3_2',
                    },
                  },
                  {
                    TEL_EXP: '(328,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Telecommunication Expense',
                      Level: '3_1_4',
                    },
                  },
                  {
                    INS_CLAIMS: '(843,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Insurance and Claims',
                      Level: '3_1_5',
                    },
                  },
                  {
                    RENT_EXP: '(3,378,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Rent Expense',
                      Level: '3_1_6',
                    },
                  },
                  {
                    SELL_MKT_EXP: '(12,001,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Selling and Marketing Expenses',
                      Level: '3_1_7',
                    },
                  },
                  {
                    OPRI_EXP: '(6,503,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operation and Maintenance Expenses',
                      Level: '3_2',
                    },
                  },
                  {
                    RCH_DEV_EXP: '(571,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Research and Development Expenses',
                      Level: '3_3',
                    },
                  },
                  {
                    DEP_AMO_DEP: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation, Amortization and Depletion',
                      Level: '3_4',
                    },
                  },
                  {
                    DEP_AMOR: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation and Amortization',
                      Level: '3_4_1',
                    },
                  },
                  {
                    DEP: '(13,480,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation',
                      Level: '3_4_1_1',
                    },
                  },
                  {
                    AMOR: '(485,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Amortization',
                      Level: '3_4_1_2',
                    },
                  },
                  {
                    TAX_OTH: '(2,635,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Taxes Other Than Income Taxes',
                      Level: '3_5',
                    },
                  },
                  {
                    PRV_EXP: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision Expense/Write-Back',
                      Level: '3_6',
                    },
                  },
                  {
                    PRV_DOUBT: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Doubtful Accounts',
                      Level: '3_6_1',
                    },
                  },
                  {
                    O_PRV_EXP: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Provision Expense/Write-Back',
                      Level: '3_6_2',
                    },
                  },
                  {
                    O_INEX_OPR: '(44,686,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Operating',
                      Level: '3_7',
                    },
                  },
                  {
                    O_INC_OPR: '5,841,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income, Operating',
                      Level: '3_7_1',
                    },
                  },
                  {
                    O_EXP_OPR: '(50,527,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Expenses, Operating',
                      Level: '3_7_2',
                    },
                  },
                  {
                    T_OPR_PnL: '172,112,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Operating Profit/Loss',
                      Level: '3',
                    },
                  },
                  {
                    NOPR_INEX: '19,385,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Non-Operating Income/Expenses, Total',
                      Level: '4',
                    },
                  },
                  {
                    T_NETFIN_INEX: '12,413,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Net Finance Income/Expense',
                      Level: '4_1',
                    },
                  },
                  {
                    NET_INT_INEX: '12,673,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Interest Income/Expense',
                      Level: '4_1_1',
                    },
                  },
                  {
                    INT_EXP_NCAP: '(454,200,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Interest Expense Net of Capitalized Interest',
                      Level: '4_1_1_1',
                    },
                  },
                  {
                    INT_INC: '13,127,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Interest Income',
                      Level: '4_1_1_2',
                    },
                  },
                  {
                    O_FIN_INEX: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Income/Expenses',
                      Level: '4_1_1_3',
                    },
                  },
                  {
                    O_FIN_EXP: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Expenses',
                      Level: '4_1_1_3_1',
                    },
                  },
                  {
                    NET_INV_INC: '7,669,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Investment Income',
                      Level: '4_2',
                    },
                  },
                  {
                    DIV_INV_INC: '83,800,000',
                    metadata: {
                      DISPLAY_NAME: 'Dividend and Investment Income',
                      Level: '4_2_1',
                    },
                  },
                  {
                    FV_UNREA_GL: '7,538,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Fair Value or Unrealized Gain/Loss on Financial Assets',
                      Level: '4_2_2',
                    },
                  },
                  {
                    INC_ASS_JV: '117,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Income from Associates, Joint Ventures and Other Participating Interests',
                      Level: '4_2_3',
                    },
                  },
                  {
                    SHARE_JV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Share of Profit and Interest from Joint Ventures',
                      Level: '4_2_3_1',
                    },
                  },
                  {
                    GL_LO_ON_FX: '(69,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Foreign Exchange',
                      Level: '4_2_4',
                    },
                  },
                  {
                    GL_ON_INV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Gain/Loss on Investments and Other Financial Instruments',
                      Level: '4_1_5',
                    },
                  },
                  {
                    GL_ON_DER: 0,
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Derivatives',
                      Level: '4_1_6',
                    },
                  },
                  {
                    IRR_INEX: '(956,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Irregular Income/Expenses',
                      Level: '4_3',
                    },
                  },
                  {
                    DISP_OF_BUS: '94,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Disposal of Businesses',
                      Level: '4_3_1',
                    },
                  },
                  {
                    AST_DISP: '(1,050,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Asset Disposals',
                      Level: '4_3_2',
                    },
                  },
                  {
                    IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Impairment/Write Off/Write Down of Other Assets',
                      Level: '4_3_3',
                    },
                  },
                  {
                    OTH_IRR_INC: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Irregular Income/Expenses',
                      Level: '4_3_4',
                    },
                  },
                  {
                    GDW_IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME: 'Goodwill Impairment/Write Off',
                      Level: '4_3_5',
                    },
                  },
                  {
                    O_INEX_NOPR: '259,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Non-Operating',
                      Level: '4_4',
                    },
                  },
                  {
                    PRETAX_INC: '191,498,200,000',
                    metadata: {
                      DISPLAY_NAME: 'Pretax Income',
                      Level: '5',
                    },
                  },
                  {
                    PRV_INC_TAX: '(63,139,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Income Tax',
                      Level: '5',
                    },
                  },
                  {
                    CUR_TAX: '(61,916,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Current Tax',
                      Level: '5_1',
                    },
                  },
                  {
                    DEF_TAX: '(1,223,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Deferred Tax',
                      Level: '5_2',
                    },
                  },
                  {
                    NETINC_COPS: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Income from Continuing Operations',
                      Level: '6',
                    },
                  },
                  {
                    NETINC_DISC: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Extraordinary Items and Discontinued Operations',
                      Level: '7',
                    },
                  },
                  {
                    NON_CON_MIN: '(2,435,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Non-Controlling/Minority Interests',
                      Level: '8',
                    },
                  },
                  {
                    NETINC_A_MIN: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Non-Controlling/Minority Interests',
                      Level: '9',
                    },
                  },
                  {
                    NETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income Available to Common Stockholders',
                      Level: '10',
                    },
                  },
                  {
                    DNETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Diluted Net Income Available to Common Stockholders',
                      Level: '11',
                    },
                  },
                  {
                    EAR_EQ_PTAX: 0,
                    metadata: {
                      DISPLAY_NAME: 'Earnings from Equity Interest, Post-Tax',
                      Level: '12',
                    },
                  },
                  {
                    OTH_ADJ_NETINC_SH: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Other Adjustments to Net Income Available to Common Stockholders',
                      Level: '13',
                    },
                  },
                ],
                properties: [
                  {
                    blg_id: 'ksdoglwsdfgr',
                    ric_code: 'abc',
                    factset_id: '786532',
                  },
                ],
                medadata: [
                  {
                    statement: 'reported',
                    audited: 'yes',
                    reported_date: '31/03/2019',
                  },
                ],
              },
              {
                SYMBOL: 'APPL',
                MARKET: 'NASDAQ',
                violated: 'false',
                blocking: 'na',
                fixed: 'true',
                approved: 'false',
                attributes: [
                  {
                    'STOCK NAME': 'Apple Inc.',
                    metadata: { DISPLAY_NAME: 'Stock', Level: 'NA' },
                  },
                  {
                    DATE_RE: 2019,
                    metadata: {
                      DISPLAY_NAME: 'Date Reported/Reinstated',
                      Level: 'NA',
                    },
                  },
                  {
                    DATE_SOURCED: '25/8/2020',
                    metadata: {
                      DISPLAY_NAME: 'DATE_SOURCED',
                      Level: 'NA',
                    },
                  },
                  {
                    TOTAL_REV: '478,390,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Revenue',
                      Level: '1',
                    },
                  },
                  {
                    BUSINESS_REV: '478,454,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Business Revenue',
                      Level: '1_1',
                    },
                  },
                  {
                    EXCISE_TAX: '(64,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Excise Taxes',
                      Level: '1_2',
                    },
                    violated: 'false',
                    blocking: 'na',
                  },
                  {
                    O_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Revenue',
                      Level: '1_3',
                    },
                  },
                  {
                    COST_OF_REV: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Revenue',
                      Level: '2',
                    },
                    blocking: 'na',
                    fixed: 'true',
                  },
                  {
                    COGS: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Goods and Services',
                      Level: '2_1',
                    },
                  },
                  {
                    CHG_INVENT: 0,
                    metadata: {
                      DISPLAY_NAME: 'Changes in Inventories',
                      Level: '2_2',
                    },
                  },
                  {
                    O_COST_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Cost of Revenue',
                      Level: '2_3',
                    },
                  },
                  {
                    GRO_PRO: '304,186,700,000',
                    metadata: { DISPLAY_NAME: 'Gross Profit', Level: '3' },
                  },
                  {
                    'OPR_INC/EXP': '(132,074,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operating Income/Expenses',
                      Level: '3',
                    },
                  },
                  {
                    SELL_GEN_ADM: '(63,325,600,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Selling, General and Administrative Expenses',
                      Level: '3_1',
                    },
                  },
                  {
                    STAFF_COSTS: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Staff Costs',
                      Level: '3_1_1',
                    },
                  },
                  {
                    O_STAFF_COST: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Staff Costs',
                      Level: '3_1_1_1',
                    },
                  },
                  {
                    GEN_ADM_EXP: '(193,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'General and Administrative Expenses',
                      Level: '3_1_2',
                    },
                  },
                  {
                    LEG_ACC_FEES: '(4,554,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Legal, Accounting, Auditing and Consulting Fees',
                      Level: '3_1_3',
                    },
                  },
                  {
                    LEG_FEES: '(500,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Legal Fees',
                      Level: '3_1_3_1',
                    },
                  },
                  {
                    O_FEES: '(4,054,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Other Professional Accounting and Consulting Fees',
                      Level: '3_1_3_2',
                    },
                  },
                  {
                    TEL_EXP: '(328,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Telecommunication Expense',
                      Level: '3_1_4',
                    },
                  },
                  {
                    INS_CLAIMS: '(843,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Insurance and Claims',
                      Level: '3_1_5',
                    },
                  },
                  {
                    RENT_EXP: '(3,378,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Rent Expense',
                      Level: '3_1_6',
                    },
                  },
                  {
                    SELL_MKT_EXP: '(12,001,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Selling and Marketing Expenses',
                      Level: '3_1_7',
                    },
                  },
                  {
                    OPRI_EXP: '(6,503,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operation and Maintenance Expenses',
                      Level: '3_2',
                    },
                  },
                  {
                    RCH_DEV_EXP: '(571,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Research and Development Expenses',
                      Level: '3_3',
                    },
                  },
                  {
                    DEP_AMO_DEP: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation, Amortization and Depletion',
                      Level: '3_4',
                    },
                  },
                  {
                    DEP_AMOR: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation and Amortization',
                      Level: '3_4_1',
                    },
                  },
                  {
                    DEP: '(13,480,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation',
                      Level: '3_4_1_1',
                    },
                  },
                  {
                    AMOR: '(485,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Amortization',
                      Level: '3_4_1_2',
                    },
                  },
                  {
                    TAX_OTH: '(2,635,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Taxes Other Than Income Taxes',
                      Level: '3_5',
                    },
                  },
                  {
                    PRV_EXP: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision Expense/Write-Back',
                      Level: '3_6',
                    },
                  },
                  {
                    PRV_DOUBT: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Doubtful Accounts',
                      Level: '3_6_1',
                    },
                  },
                  {
                    O_PRV_EXP: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Provision Expense/Write-Back',
                      Level: '3_6_2',
                    },
                  },
                  {
                    O_INEX_OPR: '(44,686,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Operating',
                      Level: '3_7',
                    },
                  },
                  {
                    O_INC_OPR: '5,841,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income, Operating',
                      Level: '3_7_1',
                    },
                  },
                  {
                    O_EXP_OPR: '(50,527,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Expenses, Operating',
                      Level: '3_7_2',
                    },
                  },
                  {
                    T_OPR_PnL: '172,112,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Operating Profit/Loss',
                      Level: '3',
                    },
                  },
                  {
                    NOPR_INEX: '19,385,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Non-Operating Income/Expenses, Total',
                      Level: '4',
                    },
                  },
                  {
                    T_NETFIN_INEX: '12,413,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Net Finance Income/Expense',
                      Level: '4_1',
                    },
                  },
                  {
                    NET_INT_INEX: '12,673,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Interest Income/Expense',
                      Level: '4_1_1',
                    },
                  },
                  {
                    INT_EXP_NCAP: '(454,200,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Interest Expense Net of Capitalized Interest',
                      Level: '4_1_1_1',
                    },
                  },
                  {
                    INT_INC: '13,127,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Interest Income',
                      Level: '4_1_1_2',
                    },
                  },
                  {
                    O_FIN_INEX: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Income/Expenses',
                      Level: '4_1_1_3',
                    },
                  },
                  {
                    O_FIN_EXP: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Expenses',
                      Level: '4_1_1_3_1',
                    },
                  },
                  {
                    NET_INV_INC: '7,669,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Investment Income',
                      Level: '4_2',
                    },
                  },
                  {
                    DIV_INV_INC: '83,800,000',
                    metadata: {
                      DISPLAY_NAME: 'Dividend and Investment Income',
                      Level: '4_2_1',
                    },
                  },
                  {
                    FV_UNREA_GL: '7,538,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Fair Value or Unrealized Gain/Loss on Financial Assets',
                      Level: '4_2_2',
                    },
                  },
                  {
                    INC_ASS_JV: '117,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Income from Associates, Joint Ventures and Other Participating Interests',
                      Level: '4_2_3',
                    },
                  },
                  {
                    SHARE_JV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Share of Profit and Interest from Joint Ventures',
                      Level: '4_2_3_1',
                    },
                  },
                  {
                    GL_LO_ON_FX: '(69,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Foreign Exchange',
                      Level: '4_2_4',
                    },
                  },
                  {
                    GL_ON_INV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Gain/Loss on Investments and Other Financial Instruments',
                      Level: '4_1_5',
                    },
                  },
                  {
                    GL_ON_DER: 0,
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Derivatives',
                      Level: '4_1_6',
                    },
                  },
                  {
                    IRR_INEX: '(956,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Irregular Income/Expenses',
                      Level: '4_3',
                    },
                  },
                  {
                    DISP_OF_BUS: '94,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Disposal of Businesses',
                      Level: '4_3_1',
                    },
                  },
                  {
                    AST_DISP: '(1,050,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Asset Disposals',
                      Level: '4_3_2',
                    },
                  },
                  {
                    IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Impairment/Write Off/Write Down of Other Assets',
                      Level: '4_3_3',
                    },
                  },
                  {
                    OTH_IRR_INC: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Irregular Income/Expenses',
                      Level: '4_3_4',
                    },
                  },
                  {
                    GDW_IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME: 'Goodwill Impairment/Write Off',
                      Level: '4_3_5',
                    },
                  },
                  {
                    O_INEX_NOPR: '259,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Non-Operating',
                      Level: '4_4',
                    },
                  },
                  {
                    PRETAX_INC: '191,498,200,000',
                    metadata: {
                      DISPLAY_NAME: 'Pretax Income',
                      Level: '5',
                    },
                  },
                  {
                    PRV_INC_TAX: '(63,139,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Income Tax',
                      Level: '5',
                    },
                  },
                  {
                    CUR_TAX: '(61,916,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Current Tax',
                      Level: '5_1',
                    },
                  },
                  {
                    DEF_TAX: '(1,223,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Deferred Tax',
                      Level: '5_2',
                    },
                  },
                  {
                    NETINC_COPS: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Income from Continuing Operations',
                      Level: '6',
                    },
                  },
                  {
                    NETINC_DISC: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Extraordinary Items and Discontinued Operations',
                      Level: '7',
                    },
                  },
                  {
                    NON_CON_MIN: '(2,435,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Non-Controlling/Minority Interests',
                      Level: '8',
                    },
                  },
                  {
                    NETINC_A_MIN: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Non-Controlling/Minority Interests',
                      Level: '9',
                    },
                  },
                  {
                    NETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income Available to Common Stockholders',
                      Level: '10',
                    },
                  },
                  {
                    DNETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Diluted Net Income Available to Common Stockholders',
                      Level: '11',
                    },
                  },
                  {
                    EAR_EQ_PTAX: 0,
                    metadata: {
                      DISPLAY_NAME: 'Earnings from Equity Interest, Post-Tax',
                      Level: '12',
                    },
                  },
                  {
                    OTH_ADJ_NETINC_SH: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Other Adjustments to Net Income Available to Common Stockholders',
                      Level: '13',
                    },
                  },
                ],
                properties: [
                  {
                    blg_id: 'ksdoglwsdfgr',
                    ric_code: 'abc',
                    factset_id: '786532',
                  },
                ],
                medadata: [
                  {
                    statement: 'reported',
                    audited: 'yes',
                    reported_date: '31/03/2019',
                  },
                ],
              },
              {
                SYMBOL: 'GILD',
                MARKET: 'NASDAQ',
                violated: 'false',
                blocking: 'na',
                fixed: 'true',
                approved: 'true',
                attributes: [
                  {
                    'STOCK NAME': 'Gilead Sciences, Inc.',
                    metadata: { DISPLAY_NAME: 'Stock', Level: 'NA' },
                  },
                  {
                    DATE_RE: 2019,
                    metadata: {
                      DISPLAY_NAME: 'Date Reported/Reinstated',
                      Level: 'NA',
                    },
                  },
                  {
                    DATE_SOURCED: '25/8/2020',
                    metadata: {
                      DISPLAY_NAME: 'DATE_SOURCED',
                      Level: 'NA',
                    },
                  },
                  {
                    TOTAL_REV: '478,390,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Revenue',
                      Level: '1',
                    },
                  },
                  {
                    BUSINESS_REV: '478,454,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Business Revenue',
                      Level: '1_1',
                    },
                  },
                  {
                    EXCISE_TAX: '(64,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Excise Taxes',
                      Level: '1_2',
                    },
                  },
                  {
                    O_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Revenue',
                      Level: '1_3',
                    },
                    violated: 'false',
                    blocking: 'na',
                  },
                  {
                    COST_OF_REV: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Revenue',
                      Level: '2',
                    },
                  },
                  {
                    COGS: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Goods and Services',
                      Level: '2_1',
                    },
                  },
                  {
                    CHG_INVENT: 0,
                    metadata: {
                      DISPLAY_NAME: 'Changes in Inventories',
                      Level: '2_2',
                    },
                  },
                  {
                    O_COST_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Cost of Revenue',
                      Level: '2_3',
                    },
                  },
                  {
                    GRO_PRO: '304,186,700,000',
                    metadata: { DISPLAY_NAME: 'Gross Profit', Level: '3' },
                  },
                  {
                    'OPR_INC/EXP': '(132,074,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operating Income/Expenses',
                      Level: '3',
                    },
                  },
                  {
                    SELL_GEN_ADM: '(63,325,600,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Selling, General and Administrative Expenses',
                      Level: '3_1',
                    },
                  },
                  {
                    STAFF_COSTS: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Staff Costs',
                      Level: '3_1_1',
                    },
                  },
                  {
                    O_STAFF_COST: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Staff Costs',
                      Level: '3_1_1_1',
                    },
                  },
                  {
                    GEN_ADM_EXP: '(193,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'General and Administrative Expenses',
                      Level: '3_1_2',
                    },
                  },
                  {
                    LEG_ACC_FEES: '(4,554,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Legal, Accounting, Auditing and Consulting Fees',
                      Level: '3_1_3',
                    },
                  },
                  {
                    LEG_FEES: '(500,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Legal Fees',
                      Level: '3_1_3_1',
                    },
                  },
                  {
                    O_FEES: '(4,054,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Other Professional Accounting and Consulting Fees',
                      Level: '3_1_3_2',
                    },
                  },
                  {
                    TEL_EXP: '(328,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Telecommunication Expense',
                      Level: '3_1_4',
                    },
                  },
                  {
                    INS_CLAIMS: '(843,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Insurance and Claims',
                      Level: '3_1_5',
                    },
                  },
                  {
                    RENT_EXP: '(3,378,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Rent Expense',
                      Level: '3_1_6',
                    },
                  },
                  {
                    SELL_MKT_EXP: '(12,001,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Selling and Marketing Expenses',
                      Level: '3_1_7',
                    },
                  },
                  {
                    OPRI_EXP: '(6,503,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operation and Maintenance Expenses',
                      Level: '3_2',
                    },
                  },
                  {
                    RCH_DEV_EXP: '(571,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Research and Development Expenses',
                      Level: '3_3',
                    },
                  },
                  {
                    DEP_AMO_DEP: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation, Amortization and Depletion',
                      Level: '3_4',
                    },
                  },
                  {
                    DEP_AMOR: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation and Amortization',
                      Level: '3_4_1',
                    },
                  },
                  {
                    DEP: '(13,480,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation',
                      Level: '3_4_1_1',
                    },
                  },
                  {
                    AMOR: '(485,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Amortization',
                      Level: '3_4_1_2',
                    },
                  },
                  {
                    TAX_OTH: '(2,635,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Taxes Other Than Income Taxes',
                      Level: '3_5',
                    },
                  },
                  {
                    PRV_EXP: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision Expense/Write-Back',
                      Level: '3_6',
                    },
                  },
                  {
                    PRV_DOUBT: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Doubtful Accounts',
                      Level: '3_6_1',
                    },
                  },
                  {
                    O_PRV_EXP: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Provision Expense/Write-Back',
                      Level: '3_6_2',
                    },
                  },
                  {
                    O_INEX_OPR: '(44,686,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Operating',
                      Level: '3_7',
                    },
                  },
                  {
                    O_INC_OPR: '5,841,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income, Operating',
                      Level: '3_7_1',
                    },
                  },
                  {
                    O_EXP_OPR: '(50,527,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Expenses, Operating',
                      Level: '3_7_2',
                    },
                  },
                  {
                    T_OPR_PnL: '172,112,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Operating Profit/Loss',
                      Level: '3',
                    },
                  },
                  {
                    NOPR_INEX: '19,385,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Non-Operating Income/Expenses, Total',
                      Level: '4',
                    },
                  },
                  {
                    T_NETFIN_INEX: '12,413,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Net Finance Income/Expense',
                      Level: '4_1',
                    },
                  },
                  {
                    NET_INT_INEX: '12,673,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Interest Income/Expense',
                      Level: '4_1_1',
                    },
                  },
                  {
                    INT_EXP_NCAP: '(454,200,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Interest Expense Net of Capitalized Interest',
                      Level: '4_1_1_1',
                    },
                  },
                  {
                    INT_INC: '13,127,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Interest Income',
                      Level: '4_1_1_2',
                    },
                  },
                  {
                    O_FIN_INEX: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Income/Expenses',
                      Level: '4_1_1_3',
                    },
                  },
                  {
                    O_FIN_EXP: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Expenses',
                      Level: '4_1_1_3_1',
                    },
                  },
                  {
                    NET_INV_INC: '7,669,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Investment Income',
                      Level: '4_2',
                    },
                  },
                  {
                    DIV_INV_INC: '83,800,000',
                    metadata: {
                      DISPLAY_NAME: 'Dividend and Investment Income',
                      Level: '4_2_1',
                    },
                  },
                  {
                    FV_UNREA_GL: '7,538,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Fair Value or Unrealized Gain/Loss on Financial Assets',
                      Level: '4_2_2',
                    },
                  },
                  {
                    INC_ASS_JV: '117,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Income from Associates, Joint Ventures and Other Participating Interests',
                      Level: '4_2_3',
                    },
                  },
                  {
                    SHARE_JV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Share of Profit and Interest from Joint Ventures',
                      Level: '4_2_3_1',
                    },
                  },
                  {
                    GL_LO_ON_FX: '(69,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Foreign Exchange',
                      Level: '4_2_4',
                    },
                  },
                  {
                    GL_ON_INV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Gain/Loss on Investments and Other Financial Instruments',
                      Level: '4_1_5',
                    },
                  },
                  {
                    GL_ON_DER: 0,
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Derivatives',
                      Level: '4_1_6',
                    },
                  },
                  {
                    IRR_INEX: '(956,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Irregular Income/Expenses',
                      Level: '4_3',
                    },
                  },
                  {
                    DISP_OF_BUS: '94,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Disposal of Businesses',
                      Level: '4_3_1',
                    },
                  },
                  {
                    AST_DISP: '(1,050,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Asset Disposals',
                      Level: '4_3_2',
                    },
                  },
                  {
                    IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Impairment/Write Off/Write Down of Other Assets',
                      Level: '4_3_3',
                    },
                  },
                  {
                    OTH_IRR_INC: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Irregular Income/Expenses',
                      Level: '4_3_4',
                    },
                  },
                  {
                    GDW_IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME: 'Goodwill Impairment/Write Off',
                      Level: '4_3_5',
                    },
                  },
                  {
                    O_INEX_NOPR: '259,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Non-Operating',
                      Level: '4_4',
                    },
                  },
                  {
                    PRETAX_INC: '191,498,200,000',
                    metadata: {
                      DISPLAY_NAME: 'Pretax Income',
                      Level: '5',
                    },
                  },
                  {
                    PRV_INC_TAX: '(63,139,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Income Tax',
                      Level: '5',
                    },
                  },
                  {
                    CUR_TAX: '(61,916,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Current Tax',
                      Level: '5_1',
                    },
                  },
                  {
                    DEF_TAX: '(1,223,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Deferred Tax',
                      Level: '5_2',
                    },
                  },
                  {
                    NETINC_COPS: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Income from Continuing Operations',
                      Level: '6',
                    },
                  },
                  {
                    NETINC_DISC: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Extraordinary Items and Discontinued Operations',
                      Level: '7',
                    },
                  },
                  {
                    NON_CON_MIN: '(2,435,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Non-Controlling/Minority Interests',
                      Level: '8',
                    },
                  },
                  {
                    NETINC_A_MIN: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Non-Controlling/Minority Interests',
                      Level: '9',
                    },
                  },
                  {
                    NETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income Available to Common Stockholders',
                      Level: '10',
                    },
                  },
                  {
                    DNETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Diluted Net Income Available to Common Stockholders',
                      Level: '11',
                    },
                  },
                  {
                    EAR_EQ_PTAX: 0,
                    metadata: {
                      DISPLAY_NAME: 'Earnings from Equity Interest, Post-Tax',
                      Level: '12',
                    },
                  },
                  {
                    OTH_ADJ_NETINC_SH: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Other Adjustments to Net Income Available to Common Stockholders',
                      Level: '13',
                    },
                  },
                ],
                properties: [
                  {
                    blg_id: 'ksdoglwsdfgr',
                    ric_code: 'abc',
                    factset_id: '786532',
                  },
                ],
                medadata: [
                  {
                    statement: 'reported',
                    audited: 'yes',
                    reported_date: '31/03/2019',
                  },
                ],
              },
              {
                SYMBOL: 'GLENMARK',
                MARKET: 'NSE',
                attributes: [
                  {
                    'STOCK NAME': 'Glenmark Pharmaceuticals Ltd',
                    metadata: { DISPLAY_NAME: 'Stock', Level: 'NA' },
                  },
                  {
                    DATE_RE: 2019,
                    metadata: {
                      DISPLAY_NAME: 'Date Reported/Reinstated',
                      Level: 'NA',
                    },
                  },
                  {
                    DATE_SOURCED: '25/8/2020',
                    metadata: {
                      DISPLAY_NAME: 'DATE_SOURCED',
                      Level: 'NA',
                    },
                  },
                  {
                    TOTAL_REV: '478,390,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Revenue',
                      Level: '1',
                    },
                  },
                  {
                    BUSINESS_REV: '478,454,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Business Revenue',
                      Level: '1_1',
                    },
                  },
                  {
                    EXCISE_TAX: '(64,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Excise Taxes',
                      Level: '1_2',
                    },
                  },
                  {
                    O_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Revenue',
                      Level: '1_3',
                    },
                  },
                  {
                    COST_OF_REV: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Revenue',
                      Level: '2',
                    },
                  },
                  {
                    COGS: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Goods and Services',
                      Level: '2_1',
                    },
                  },
                  {
                    CHG_INVENT: 0,
                    metadata: {
                      DISPLAY_NAME: 'Changes in Inventories',
                      Level: '2_2',
                    },
                  },
                  {
                    O_COST_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Cost of Revenue',
                      Level: '2_3',
                    },
                  },
                  {
                    GRO_PRO: '304,186,700,000',
                    metadata: { DISPLAY_NAME: 'Gross Profit', Level: '3' },
                  },
                  {
                    'OPR_INC/EXP': '(132,074,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operating Income/Expenses',
                      Level: '3',
                    },
                  },
                  {
                    SELL_GEN_ADM: '(63,325,600,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Selling, General and Administrative Expenses',
                      Level: '3_1',
                    },
                  },
                  {
                    STAFF_COSTS: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Staff Costs',
                      Level: '3_1_1',
                    },
                  },
                  {
                    O_STAFF_COST: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Staff Costs',
                      Level: '3_1_1_1',
                    },
                  },
                  {
                    GEN_ADM_EXP: '(193,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'General and Administrative Expenses',
                      Level: '3_1_2',
                    },
                  },
                  {
                    LEG_ACC_FEES: '(4,554,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Legal, Accounting, Auditing and Consulting Fees',
                      Level: '3_1_3',
                    },
                  },
                  {
                    LEG_FEES: '(500,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Legal Fees',
                      Level: '3_1_3_1',
                    },
                  },
                  {
                    O_FEES: '(4,054,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Other Professional Accounting and Consulting Fees',
                      Level: '3_1_3_2',
                    },
                  },
                  {
                    TEL_EXP: '(328,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Telecommunication Expense',
                      Level: '3_1_4',
                    },
                  },
                  {
                    INS_CLAIMS: '(843,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Insurance and Claims',
                      Level: '3_1_5',
                    },
                  },
                  {
                    RENT_EXP: '(3,378,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Rent Expense',
                      Level: '3_1_6',
                    },
                  },
                  {
                    SELL_MKT_EXP: '(12,001,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Selling and Marketing Expenses',
                      Level: '3_1_7',
                    },
                  },
                  {
                    OPRI_EXP: '(6,503,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operation and Maintenance Expenses',
                      Level: '3_2',
                    },
                  },
                  {
                    RCH_DEV_EXP: '(571,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Research and Development Expenses',
                      Level: '3_3',
                    },
                  },
                  {
                    DEP_AMO_DEP: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation, Amortization and Depletion',
                      Level: '3_4',
                    },
                  },
                  {
                    DEP_AMOR: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation and Amortization',
                      Level: '3_4_1',
                    },
                  },
                  {
                    DEP: '(13,480,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation',
                      Level: '3_4_1_1',
                    },
                  },
                  {
                    AMOR: '(485,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Amortization',
                      Level: '3_4_1_2',
                    },
                  },
                  {
                    TAX_OTH: '(2,635,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Taxes Other Than Income Taxes',
                      Level: '3_5',
                    },
                  },
                  {
                    PRV_EXP: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision Expense/Write-Back',
                      Level: '3_6',
                    },
                  },
                  {
                    PRV_DOUBT: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Doubtful Accounts',
                      Level: '3_6_1',
                    },
                  },
                  {
                    O_PRV_EXP: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Provision Expense/Write-Back',
                      Level: '3_6_2',
                    },
                  },
                  {
                    O_INEX_OPR: '(44,686,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Operating',
                      Level: '3_7',
                    },
                  },
                  {
                    O_INC_OPR: '5,841,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income, Operating',
                      Level: '3_7_1',
                    },
                  },
                  {
                    O_EXP_OPR: '(50,527,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Expenses, Operating',
                      Level: '3_7_2',
                    },
                  },
                  {
                    T_OPR_PnL: '172,112,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Operating Profit/Loss',
                      Level: '3',
                    },
                  },
                  {
                    NOPR_INEX: '19,385,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Non-Operating Income/Expenses, Total',
                      Level: '4',
                    },
                  },
                  {
                    T_NETFIN_INEX: '12,413,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Net Finance Income/Expense',
                      Level: '4_1',
                    },
                  },
                  {
                    NET_INT_INEX: '12,673,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Interest Income/Expense',
                      Level: '4_1_1',
                    },
                  },
                  {
                    INT_EXP_NCAP: '(454,200,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Interest Expense Net of Capitalized Interest',
                      Level: '4_1_1_1',
                    },
                  },
                  {
                    INT_INC: '13,127,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Interest Income',
                      Level: '4_1_1_2',
                    },
                  },
                  {
                    O_FIN_INEX: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Income/Expenses',
                      Level: '4_1_1_3',
                    },
                  },
                  {
                    O_FIN_EXP: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Expenses',
                      Level: '4_1_1_3_1',
                    },
                  },
                  {
                    NET_INV_INC: '7,669,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Investment Income',
                      Level: '4_2',
                    },
                  },
                  {
                    DIV_INV_INC: '83,800,000',
                    metadata: {
                      DISPLAY_NAME: 'Dividend and Investment Income',
                      Level: '4_2_1',
                    },
                  },
                  {
                    FV_UNREA_GL: '7,538,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Fair Value or Unrealized Gain/Loss on Financial Assets',
                      Level: '4_2_2',
                    },
                  },
                  {
                    INC_ASS_JV: '117,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Income from Associates, Joint Ventures and Other Participating Interests',
                      Level: '4_2_3',
                    },
                  },
                  {
                    SHARE_JV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Share of Profit and Interest from Joint Ventures',
                      Level: '4_2_3_1',
                    },
                  },
                  {
                    GL_LO_ON_FX: '(69,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Foreign Exchange',
                      Level: '4_2_4',
                    },
                  },
                  {
                    GL_ON_INV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Gain/Loss on Investments and Other Financial Instruments',
                      Level: '4_1_5',
                    },
                  },
                  {
                    GL_ON_DER: 0,
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Derivatives',
                      Level: '4_1_6',
                    },
                  },
                  {
                    IRR_INEX: '(956,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Irregular Income/Expenses',
                      Level: '4_3',
                    },
                  },
                  {
                    DISP_OF_BUS: '94,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Disposal of Businesses',
                      Level: '4_3_1',
                    },
                  },
                  {
                    AST_DISP: '(1,050,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Asset Disposals',
                      Level: '4_3_2',
                    },
                  },
                  {
                    IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Impairment/Write Off/Write Down of Other Assets',
                      Level: '4_3_3',
                    },
                  },
                  {
                    OTH_IRR_INC: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Irregular Income/Expenses',
                      Level: '4_3_4',
                    },
                  },
                  {
                    GDW_IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME: 'Goodwill Impairment/Write Off',
                      Level: '4_3_5',
                    },
                  },
                  {
                    O_INEX_NOPR: '259,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Non-Operating',
                      Level: '4_4',
                    },
                  },
                  {
                    PRETAX_INC: '191,498,200,000',
                    metadata: {
                      DISPLAY_NAME: 'Pretax Income',
                      Level: '5',
                    },
                  },
                  {
                    PRV_INC_TAX: '(63,139,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Income Tax',
                      Level: '5',
                    },
                  },
                  {
                    CUR_TAX: '(61,916,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Current Tax',
                      Level: '5_1',
                    },
                  },
                  {
                    DEF_TAX: '(1,223,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Deferred Tax',
                      Level: '5_2',
                    },
                  },
                  {
                    NETINC_COPS: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Income from Continuing Operations',
                      Level: '6',
                    },
                  },
                  {
                    NETINC_DISC: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Extraordinary Items and Discontinued Operations',
                      Level: '7',
                    },
                  },
                  {
                    NON_CON_MIN: '(2,435,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Non-Controlling/Minority Interests',
                      Level: '8',
                    },
                  },
                  {
                    NETINC_A_MIN: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Non-Controlling/Minority Interests',
                      Level: '9',
                    },
                  },
                  {
                    NETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income Available to Common Stockholders',
                      Level: '10',
                    },
                  },
                  {
                    DNETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Diluted Net Income Available to Common Stockholders',
                      Level: '11',
                    },
                  },
                  {
                    EAR_EQ_PTAX: 0,
                    metadata: {
                      DISPLAY_NAME: 'Earnings from Equity Interest, Post-Tax',
                      Level: '12',
                    },
                  },
                  {
                    OTH_ADJ_NETINC_SH: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Other Adjustments to Net Income Available to Common Stockholders',
                      Level: '13',
                    },
                  },
                ],
                properties: [
                  {
                    blg_id: 'ksdoglwsdfgr',
                    ric_code: 'abc',
                    factset_id: '786532',
                  },
                ],
                medadata: [
                  {
                    statement: 'reported',
                    audited: 'yes',
                    reported_date: '31/03/2019',
                  },
                ],
              },
              {
                SYMBOL: 'JNPR',
                MARKET: 'NYSE',
                attributes: [
                  {
                    'STOCK NAME': 'Juniper Networks, Inc.',
                    metadata: { DISPLAY_NAME: 'Stock', Level: 'NA' },
                  },
                  {
                    DATE_RE: 2019,
                    metadata: {
                      DISPLAY_NAME: 'Date Reported/Reinstated',
                      Level: 'NA',
                    },
                  },
                  {
                    DATE_SOURCED: '25/8/2020',
                    metadata: {
                      DISPLAY_NAME: 'DATE_SOURCED',
                      Level: 'NA',
                    },
                  },
                  {
                    TOTAL_REV: '478,390,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Revenue',
                      Level: '1',
                    },
                  },
                  {
                    BUSINESS_REV: '478,454,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Business Revenue',
                      Level: '1_1',
                    },
                  },
                  {
                    EXCISE_TAX: '(64,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Excise Taxes',
                      Level: '1_2',
                    },
                  },
                  {
                    O_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Revenue',
                      Level: '1_3',
                    },
                  },
                  {
                    COST_OF_REV: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Revenue',
                      Level: '2',
                    },
                  },
                  {
                    COGS: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Goods and Services',
                      Level: '2_1',
                    },
                  },
                  {
                    CHG_INVENT: 0,
                    metadata: {
                      DISPLAY_NAME: 'Changes in Inventories',
                      Level: '2_2',
                    },
                  },
                  {
                    O_COST_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Cost of Revenue',
                      Level: '2_3',
                    },
                  },
                  {
                    GRO_PRO: '304,186,700,000',
                    metadata: { DISPLAY_NAME: 'Gross Profit', Level: '3' },
                  },
                  {
                    'OPR_INC/EXP': '(132,074,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operating Income/Expenses',
                      Level: '3',
                    },
                  },
                  {
                    SELL_GEN_ADM: '(63,325,600,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Selling, General and Administrative Expenses',
                      Level: '3_1',
                    },
                  },
                  {
                    STAFF_COSTS: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Staff Costs',
                      Level: '3_1_1',
                    },
                  },
                  {
                    O_STAFF_COST: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Staff Costs',
                      Level: '3_1_1_1',
                    },
                  },
                  {
                    GEN_ADM_EXP: '(193,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'General and Administrative Expenses',
                      Level: '3_1_2',
                    },
                  },
                  {
                    LEG_ACC_FEES: '(4,554,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Legal, Accounting, Auditing and Consulting Fees',
                      Level: '3_1_3',
                    },
                  },
                  {
                    LEG_FEES: '(500,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Legal Fees',
                      Level: '3_1_3_1',
                    },
                  },
                  {
                    O_FEES: '(4,054,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Other Professional Accounting and Consulting Fees',
                      Level: '3_1_3_2',
                    },
                  },
                  {
                    TEL_EXP: '(328,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Telecommunication Expense',
                      Level: '3_1_4',
                    },
                  },
                  {
                    INS_CLAIMS: '(843,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Insurance and Claims',
                      Level: '3_1_5',
                    },
                  },
                  {
                    RENT_EXP: '(3,378,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Rent Expense',
                      Level: '3_1_6',
                    },
                  },
                  {
                    SELL_MKT_EXP: '(12,001,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Selling and Marketing Expenses',
                      Level: '3_1_7',
                    },
                  },
                  {
                    OPRI_EXP: '(6,503,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operation and Maintenance Expenses',
                      Level: '3_2',
                    },
                  },
                  {
                    RCH_DEV_EXP: '(571,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Research and Development Expenses',
                      Level: '3_3',
                    },
                  },
                  {
                    DEP_AMO_DEP: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation, Amortization and Depletion',
                      Level: '3_4',
                    },
                  },
                  {
                    DEP_AMOR: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation and Amortization',
                      Level: '3_4_1',
                    },
                  },
                  {
                    DEP: '(13,480,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation',
                      Level: '3_4_1_1',
                    },
                  },
                  {
                    AMOR: '(485,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Amortization',
                      Level: '3_4_1_2',
                    },
                  },
                  {
                    TAX_OTH: '(2,635,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Taxes Other Than Income Taxes',
                      Level: '3_5',
                    },
                  },
                  {
                    PRV_EXP: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision Expense/Write-Back',
                      Level: '3_6',
                    },
                  },
                  {
                    PRV_DOUBT: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Doubtful Accounts',
                      Level: '3_6_1',
                    },
                  },
                  {
                    O_PRV_EXP: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Provision Expense/Write-Back',
                      Level: '3_6_2',
                    },
                  },
                  {
                    O_INEX_OPR: '(44,686,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Operating',
                      Level: '3_7',
                    },
                  },
                  {
                    O_INC_OPR: '5,841,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income, Operating',
                      Level: '3_7_1',
                    },
                  },
                  {
                    O_EXP_OPR: '(50,527,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Expenses, Operating',
                      Level: '3_7_2',
                    },
                  },
                  {
                    T_OPR_PnL: '172,112,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Operating Profit/Loss',
                      Level: '3',
                    },
                  },
                  {
                    NOPR_INEX: '19,385,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Non-Operating Income/Expenses, Total',
                      Level: '4',
                    },
                  },
                  {
                    T_NETFIN_INEX: '12,413,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Net Finance Income/Expense',
                      Level: '4_1',
                    },
                  },
                  {
                    NET_INT_INEX: '12,673,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Interest Income/Expense',
                      Level: '4_1_1',
                    },
                  },
                  {
                    INT_EXP_NCAP: '(454,200,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Interest Expense Net of Capitalized Interest',
                      Level: '4_1_1_1',
                    },
                  },
                  {
                    INT_INC: '13,127,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Interest Income',
                      Level: '4_1_1_2',
                    },
                  },
                  {
                    O_FIN_INEX: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Income/Expenses',
                      Level: '4_1_1_3',
                    },
                  },
                  {
                    O_FIN_EXP: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Expenses',
                      Level: '4_1_1_3_1',
                    },
                  },
                  {
                    NET_INV_INC: '7,669,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Investment Income',
                      Level: '4_2',
                    },
                  },
                  {
                    DIV_INV_INC: '83,800,000',
                    metadata: {
                      DISPLAY_NAME: 'Dividend and Investment Income',
                      Level: '4_2_1',
                    },
                  },
                  {
                    FV_UNREA_GL: '7,538,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Fair Value or Unrealized Gain/Loss on Financial Assets',
                      Level: '4_2_2',
                    },
                  },
                  {
                    INC_ASS_JV: '117,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Income from Associates, Joint Ventures and Other Participating Interests',
                      Level: '4_2_3',
                    },
                  },
                  {
                    SHARE_JV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Share of Profit and Interest from Joint Ventures',
                      Level: '4_2_3_1',
                    },
                  },
                  {
                    GL_LO_ON_FX: '(69,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Foreign Exchange',
                      Level: '4_2_4',
                    },
                  },
                  {
                    GL_ON_INV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Gain/Loss on Investments and Other Financial Instruments',
                      Level: '4_1_5',
                    },
                  },
                  {
                    GL_ON_DER: 0,
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Derivatives',
                      Level: '4_1_6',
                    },
                  },
                  {
                    IRR_INEX: '(956,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Irregular Income/Expenses',
                      Level: '4_3',
                    },
                  },
                  {
                    DISP_OF_BUS: '94,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Disposal of Businesses',
                      Level: '4_3_1',
                    },
                  },
                  {
                    AST_DISP: '(1,050,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Asset Disposals',
                      Level: '4_3_2',
                    },
                  },
                  {
                    IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Impairment/Write Off/Write Down of Other Assets',
                      Level: '4_3_3',
                    },
                  },
                  {
                    OTH_IRR_INC: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Irregular Income/Expenses',
                      Level: '4_3_4',
                    },
                  },
                  {
                    GDW_IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME: 'Goodwill Impairment/Write Off',
                      Level: '4_3_5',
                    },
                  },
                  {
                    O_INEX_NOPR: '259,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Non-Operating',
                      Level: '4_4',
                    },
                  },
                  {
                    PRETAX_INC: '191,498,200,000',
                    metadata: {
                      DISPLAY_NAME: 'Pretax Income',
                      Level: '5',
                    },
                  },
                  {
                    PRV_INC_TAX: '(63,139,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Income Tax',
                      Level: '5',
                    },
                  },
                  {
                    CUR_TAX: '(61,916,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Current Tax',
                      Level: '5_1',
                    },
                  },
                  {
                    DEF_TAX: '(1,223,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Deferred Tax',
                      Level: '5_2',
                    },
                  },
                  {
                    NETINC_COPS: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Income from Continuing Operations',
                      Level: '6',
                    },
                  },
                  {
                    NETINC_DISC: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Extraordinary Items and Discontinued Operations',
                      Level: '7',
                    },
                  },
                  {
                    NON_CON_MIN: '(2,435,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Non-Controlling/Minority Interests',
                      Level: '8',
                    },
                  },
                  {
                    NETINC_A_MIN: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Non-Controlling/Minority Interests',
                      Level: '9',
                    },
                  },
                  {
                    NETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income Available to Common Stockholders',
                      Level: '10',
                    },
                  },
                  {
                    DNETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Diluted Net Income Available to Common Stockholders',
                      Level: '11',
                    },
                  },
                  {
                    EAR_EQ_PTAX: 0,
                    metadata: {
                      DISPLAY_NAME: 'Earnings from Equity Interest, Post-Tax',
                      Level: '12',
                    },
                  },
                  {
                    OTH_ADJ_NETINC_SH: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Other Adjustments to Net Income Available to Common Stockholders',
                      Level: '13',
                    },
                  },
                ],
                properties: [
                  {
                    blg_id: 'ksdoglwsdfgr',
                    ric_code: 'abc',
                    factset_id: '786532',
                  },
                ],
                medadata: [
                  {
                    statement: 'reported',
                    audited: 'yes',
                    reported_date: '31/03/2019',
                  },
                ],
              },
              {
                SYMBOL: 'TEVA',
                MARKET: 'TLV',
                attributes: [
                  {
                    'STOCK NAME': 'Teva Pharmaceutical Industries Limited',
                    metadata: { DISPLAY_NAME: 'Stock', Level: 'NA' },
                  },
                  {
                    DATE_RE: 2019,
                    metadata: {
                      DISPLAY_NAME: 'Date Reported/Reinstated',
                      Level: 'NA',
                    },
                  },
                  {
                    DATE_SOURCED: '25/8/2020',
                    metadata: {
                      DISPLAY_NAME: 'DATE_SOURCED',
                      Level: 'NA',
                    },
                  },
                  {
                    TOTAL_REV: '478,390,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Revenue',
                      Level: '1',
                    },
                  },
                  {
                    BUSINESS_REV: '478,454,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Business Revenue',
                      Level: '1_1',
                    },
                  },
                  {
                    EXCISE_TAX: '(64,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Excise Taxes',
                      Level: '1_2',
                    },
                  },
                  {
                    O_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Revenue',
                      Level: '1_3',
                    },
                  },
                  {
                    COST_OF_REV: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Revenue',
                      Level: '2',
                    },
                  },
                  {
                    COGS: '(174,203,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Cost of Goods and Services',
                      Level: '2_1',
                    },
                  },
                  {
                    CHG_INVENT: 0,
                    metadata: {
                      DISPLAY_NAME: 'Changes in Inventories',
                      Level: '2_2',
                    },
                  },
                  {
                    O_COST_REV: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Cost of Revenue',
                      Level: '2_3',
                    },
                  },
                  {
                    GRO_PRO: '304,186,700,000',
                    metadata: { DISPLAY_NAME: 'Gross Profit', Level: '3' },
                  },
                  {
                    'OPR_INC/EXP': '(132,074,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operating Income/Expenses',
                      Level: '3',
                    },
                  },
                  {
                    SELL_GEN_ADM: '(63,325,600,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Selling, General and Administrative Expenses',
                      Level: '3_1',
                    },
                  },
                  {
                    STAFF_COSTS: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Staff Costs',
                      Level: '3_1_1',
                    },
                  },
                  {
                    O_STAFF_COST: '(42,025,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Staff Costs',
                      Level: '3_1_1_1',
                    },
                  },
                  {
                    GEN_ADM_EXP: '(193,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'General and Administrative Expenses',
                      Level: '3_1_2',
                    },
                  },
                  {
                    LEG_ACC_FEES: '(4,554,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Legal, Accounting, Auditing and Consulting Fees',
                      Level: '3_1_3',
                    },
                  },
                  {
                    LEG_FEES: '(500,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Legal Fees',
                      Level: '3_1_3_1',
                    },
                  },
                  {
                    O_FEES: '(4,054,700,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Other Professional Accounting and Consulting Fees',
                      Level: '3_1_3_2',
                    },
                  },
                  {
                    TEL_EXP: '(328,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Telecommunication Expense',
                      Level: '3_1_4',
                    },
                  },
                  {
                    INS_CLAIMS: '(843,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Insurance and Claims',
                      Level: '3_1_5',
                    },
                  },
                  {
                    RENT_EXP: '(3,378,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Rent Expense',
                      Level: '3_1_6',
                    },
                  },
                  {
                    SELL_MKT_EXP: '(12,001,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Selling and Marketing Expenses',
                      Level: '3_1_7',
                    },
                  },
                  {
                    OPRI_EXP: '(6,503,300,000)',
                    metadata: {
                      DISPLAY_NAME: 'Operation and Maintenance Expenses',
                      Level: '3_2',
                    },
                  },
                  {
                    RCH_DEV_EXP: '(571,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Research and Development Expenses',
                      Level: '3_3',
                    },
                  },
                  {
                    DEP_AMO_DEP: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation, Amortization and Depletion',
                      Level: '3_4',
                    },
                  },
                  {
                    DEP_AMOR: '(13,966,100,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation and Amortization',
                      Level: '3_4_1',
                    },
                  },
                  {
                    DEP: '(13,480,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Depreciation',
                      Level: '3_4_1_1',
                    },
                  },
                  {
                    AMOR: '(485,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Amortization',
                      Level: '3_4_1_2',
                    },
                  },
                  {
                    TAX_OTH: '(2,635,600,000)',
                    metadata: {
                      DISPLAY_NAME: 'Taxes Other Than Income Taxes',
                      Level: '3_5',
                    },
                  },
                  {
                    PRV_EXP: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision Expense/Write-Back',
                      Level: '3_6',
                    },
                  },
                  {
                    PRV_DOUBT: '(385,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Doubtful Accounts',
                      Level: '3_6_1',
                    },
                  },
                  {
                    O_PRV_EXP: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Provision Expense/Write-Back',
                      Level: '3_6_2',
                    },
                  },
                  {
                    O_INEX_OPR: '(44,686,400,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Operating',
                      Level: '3_7',
                    },
                  },
                  {
                    O_INC_OPR: '5,841,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income, Operating',
                      Level: '3_7_1',
                    },
                  },
                  {
                    O_EXP_OPR: '(50,527,900,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Expenses, Operating',
                      Level: '3_7_2',
                    },
                  },
                  {
                    T_OPR_PnL: '172,112,500,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Operating Profit/Loss',
                      Level: '3',
                    },
                  },
                  {
                    NOPR_INEX: '19,385,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Non-Operating Income/Expenses, Total',
                      Level: '4',
                    },
                  },
                  {
                    T_NETFIN_INEX: '12,413,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Total Net Finance Income/Expense',
                      Level: '4_1',
                    },
                  },
                  {
                    NET_INT_INEX: '12,673,700,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Interest Income/Expense',
                      Level: '4_1_1',
                    },
                  },
                  {
                    INT_EXP_NCAP: '(454,200,000)',
                    metadata: {
                      DISPLAY_NAME:
                        'Interest Expense Net of Capitalized Interest',
                      Level: '4_1_1_1',
                    },
                  },
                  {
                    INT_INC: '13,127,900,000',
                    metadata: {
                      DISPLAY_NAME: 'Interest Income',
                      Level: '4_1_1_2',
                    },
                  },
                  {
                    O_FIN_INEX: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Income/Expenses',
                      Level: '4_1_1_3',
                    },
                  },
                  {
                    O_FIN_EXP: '(259,800,000)',
                    metadata: {
                      DISPLAY_NAME: 'Other Finance Expenses',
                      Level: '4_1_1_3_1',
                    },
                  },
                  {
                    NET_INV_INC: '7,669,300,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Investment Income',
                      Level: '4_2',
                    },
                  },
                  {
                    DIV_INV_INC: '83,800,000',
                    metadata: {
                      DISPLAY_NAME: 'Dividend and Investment Income',
                      Level: '4_2_1',
                    },
                  },
                  {
                    FV_UNREA_GL: '7,538,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Fair Value or Unrealized Gain/Loss on Financial Assets',
                      Level: '4_2_2',
                    },
                  },
                  {
                    INC_ASS_JV: '117,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Income from Associates, Joint Ventures and Other Participating Interests',
                      Level: '4_2_3',
                    },
                  },
                  {
                    SHARE_JV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Share of Profit and Interest from Joint Ventures',
                      Level: '4_2_3_1',
                    },
                  },
                  {
                    GL_LO_ON_FX: '(69,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Foreign Exchange',
                      Level: '4_2_4',
                    },
                  },
                  {
                    GL_ON_INV: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Gain/Loss on Investments and Other Financial Instruments',
                      Level: '4_1_5',
                    },
                  },
                  {
                    GL_ON_DER: 0,
                    metadata: {
                      DISPLAY_NAME: 'Gain/Loss on Derivatives',
                      Level: '4_1_6',
                    },
                  },
                  {
                    IRR_INEX: '(956,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Irregular Income/Expenses',
                      Level: '4_3',
                    },
                  },
                  {
                    DISP_OF_BUS: '94,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Disposal of Businesses',
                      Level: '4_3_1',
                    },
                  },
                  {
                    AST_DISP: '(1,050,500,000)',
                    metadata: {
                      DISPLAY_NAME: 'Asset Disposals',
                      Level: '4_3_2',
                    },
                  },
                  {
                    IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Impairment/Write Off/Write Down of Other Assets',
                      Level: '4_3_3',
                    },
                  },
                  {
                    OTH_IRR_INC: 0,
                    metadata: {
                      DISPLAY_NAME: 'Other Irregular Income/Expenses',
                      Level: '4_3_4',
                    },
                  },
                  {
                    GDW_IMPAIR: 0,
                    metadata: {
                      DISPLAY_NAME: 'Goodwill Impairment/Write Off',
                      Level: '4_3_5',
                    },
                  },
                  {
                    O_INEX_NOPR: '259,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Other Income/Expense, Non-Operating',
                      Level: '4_4',
                    },
                  },
                  {
                    PRETAX_INC: '191,498,200,000',
                    metadata: {
                      DISPLAY_NAME: 'Pretax Income',
                      Level: '5',
                    },
                  },
                  {
                    PRV_INC_TAX: '(63,139,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Provision for Income Tax',
                      Level: '5',
                    },
                  },
                  {
                    CUR_TAX: '(61,916,200,000)',
                    metadata: {
                      DISPLAY_NAME: 'Current Tax',
                      Level: '5_1',
                    },
                  },
                  {
                    DEF_TAX: '(1,223,000,000)',
                    metadata: {
                      DISPLAY_NAME: 'Deferred Tax',
                      Level: '5_2',
                    },
                  },
                  {
                    NETINC_COPS: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME: 'Net Income from Continuing Operations',
                      Level: '6',
                    },
                  },
                  {
                    NETINC_DISC: '128,359,000,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Extraordinary Items and Discontinued Operations',
                      Level: '7',
                    },
                  },
                  {
                    NON_CON_MIN: '(2,435,700,000)',
                    metadata: {
                      DISPLAY_NAME: 'Non-Controlling/Minority Interests',
                      Level: '8',
                    },
                  },
                  {
                    NETINC_A_MIN: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income after Non-Controlling/Minority Interests',
                      Level: '9',
                    },
                  },
                  {
                    NETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Net Income Available to Common Stockholders',
                      Level: '10',
                    },
                  },
                  {
                    DNETINC_SH: '125,923,300,000',
                    metadata: {
                      DISPLAY_NAME:
                        'Diluted Net Income Available to Common Stockholders',
                      Level: '11',
                    },
                  },
                  {
                    EAR_EQ_PTAX: 0,
                    metadata: {
                      DISPLAY_NAME: 'Earnings from Equity Interest, Post-Tax',
                      Level: '12',
                    },
                  },
                  {
                    OTH_ADJ_NETINC_SH: 0,
                    metadata: {
                      DISPLAY_NAME:
                        'Other Adjustments to Net Income Available to Common Stockholders',
                      Level: '13',
                    },
                  },
                ],
                properties: [
                  {
                    blg_id: 'ksdoglwsdfgr',
                    ric_code: 'abc',
                    factset_id: '786532',
                  },
                ],
                medadata: [
                  {
                    statement: 'reported',
                    audited: 'yes',
                    reported_date: '31/03/2019',
                  },
                ],
              },
            ],
          },
          { name: 'COMPANY_BALANCE_SHEET' },
          { name: 'COMPANY_CASH_FLOW' },
        ],
      },
    ],
  },
};
