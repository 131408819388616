import React from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { LocaleStoreState } from '../../store/locale-store';
import { ROUTES } from '../../constants';
import Header from '../../components/Header';
import Sidebar from './Sidebar';
import Toolbar from './Toolbar';
import DataTable from './DataTable';
import styles from './Runtime.module.scss';

type RouteParams = {
  rateType?: string;
};

type RuntimeProps = RouteComponentProps<RouteParams> & {
  locale: LocaleStoreState;
};

function Runtime(props: RuntimeProps) {
  // eslint-disable-next-line
  const rateType = props.match.params?.rateType;

  return (
    <React.Fragment>
      <Header locale={props.locale} />
      <main>
        <div className={styles.pageWrapper}>
          <Sidebar />
          <div className={styles.contentWrapper}>
            <Toolbar />
            <div className={styles.ratesNav}>
              <NavLink
                className={styles.ratesNavAnchor}
                activeClassName={styles.ratesNavActive}
                to={{
                  pathname: `${ROUTES.RUNTIME}/raw`,
                }}
                exact
              >
                Raw rates
              </NavLink>
              <NavLink
                className={styles.ratesNavAnchor}
                activeClassName={styles.ratesNavActive}
                to={{
                  pathname: `${ROUTES.RUNTIME}/aggregated`,
                }}
                exact
              >
                Aggregated rates
              </NavLink>
              <NavLink
                className={styles.ratesNavAnchor}
                activeClassName={styles.ratesNavActive}
                to={{
                  pathname: `${ROUTES.RUNTIME}/historical`,
                }}
                exact
              >
                Historical rates
              </NavLink>
            </div>
            <DataTable />
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Runtime;
