import React from 'react';
import { fetchRates } from '../../../api/rates';

const extractColumnsAndDataFromRes = (res: any) => {
  const incomeStatement = res.raw_rates[0].schemas.find(
    (s: any) => s.name === 'COMPANY_INCOME_STATEMENT'
  );
  let columns: { Header: string; accessor: string }[] = [];
  let data: { [key: string]: string }[] = [];

  columns.push({ Header: 'Status', accessor: 'status' });
  columns.push({ Header: 'Symbol', accessor: 'SYMBOL' });
  columns.push({ Header: 'Market', accessor: 'MARKET' });

  const attrKeysToFilterOutForVal = [
    'status',
    'blocking',
    'violated',
    'fixed',
    'metadata',
  ];

  incomeStatement.rates.forEach((rate: any) => {
    let tableRowData: any = {};
    tableRowData.status = rate.status;
    tableRowData.SYMBOL = rate.SYMBOL;
    tableRowData.MARKET = rate.MARKET;

    rate.attributes.forEach((attr: any) => {
      const attrAccessorKey = Object.keys(attr).find(
        (k) => !attrKeysToFilterOutForVal.includes(k)
      ) as string;

      const attrDisplayName = attr.metadata.DISPLAY_NAME;
      const columnHeaderAlreadyExists = columns.some(
        (c) => c.Header === attrDisplayName
      );
      if (!columnHeaderAlreadyExists) {
        columns.push({
          Header: attrDisplayName,
          accessor: attrAccessorKey,
        });
      }
      tableRowData[attrAccessorKey] = attr[attrAccessorKey];
    });
    data.push(tableRowData);
  });

  return { columns, data };
};

const TableProvider = ({ children }: { children: React.ReactNode }) => {
  const [columns, setColumns] = React.useState<
    { Header: string; accessor: string }[]
  >();
  const [data, setData] = React.useState<{ [key: string]: string }[]>();

  React.useEffect(() => {
    fetchRates().then((res) => {
      const { columns, data } = extractColumnsAndDataFromRes(res);
      setColumns(columns);
      setData(data);
    });
  }, []);

  if (!data || !columns) return null;

  // @ts-ignore
  return <>{children({ columns, data })}</>;
};

export default TableProvider;
