export const STRAPI = {
  BASE_URL: 'https://strapi.apiro.com.au',
  INSTANCE_SPACE_PATH: '/App-profile-instances/2',
  LOCALES_PATH: '/locales/1',
  USERS_PATH: '/users',
  ROLES_PATH: '/users-permissions/roles',
  DOC_TEMPLATES_PATH: '/doc-templates',
  DEFAULT_LOCALE: 'en',
};

export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  RESET_PASSWORD: '/reset-password',
  RUNTIME_WITH_PARAM: '/runtime/:rateType',
  RUNTIME: '/runtime',
  DASHBOARD: '/dashboard',
  CONFIGURATION: '/configuration',
  ADMIN: '/admin',
  PRIVACY: '/privacy',
  APIRO_FORMS: '/apiroforms/forms',
  APIRO_FORMS_MANAGE_CLIENTS: '/apiroforms/clients/manage',
  APIRO_FORMS_MANAGE_CLIENTS_WITH_PARAM: '/apiroforms/clients/manage/:roleId',
  APIRO_FORMS_ADD_CLIENTS: '/apiroforms/clients/add',
  APIRO_FORMS_EDIT_CLIENTS: '/apiroforms/clients/edit',
  APIRO_FORMS_EDIT_CLIENTS_WITH_PARAM: '/apiroforms/clients/edit/:id',
  APIRO_FORMS_MANAGE_ROLES: '/apiroforms/roles/manage',
  APIRO_FORMS_EDIT_ROLES: '/apiroforms/roles/edit',
  APIRO_FORMS_EDIT_ROLES_WITH_PARAM: '/apiroforms/roles/edit/:id',
  APIRO_FORMS_ADD_ROLES: '/apiroforms/roles/add',
  APIRO_FORMS_ASSIGN_FORMS: '/apiroforms/forms/assign',
  APIRO_FORMS_ASSIGN_FORMS_WITH_PARAM: '/apiroforms/forms/assign/:userId',
  APIRO_FORMS_VIEW_FORMS_WITH_PARAM: '/apiroforms/forms/view/:id',
  APIRO_FORMS_VIEW_TYPE_WITH_PARAM: '/apiroforms/forms/viewtype/:type/:formId',
};

export const ROLES = {
  AUTHENTICATED: 'Authenticated',
  FINANCIALADVISER: 'FinancialAdviser',
  FINANCIALADVISERCLIENT: 'FinancialAdviserClient',
  PUBLIC: 'Public',
  SUPERADMIN: 'Superadmin',
};

export const ALL_ROLES = Object.values(ROLES);

export const SUPERUSER_ROLES = [ROLES.FINANCIALADVISER, ROLES.SUPERADMIN];

export const REGULARUSER_ROLES = [
  ROLES.AUTHENTICATED,
  ROLES.FINANCIALADVISERCLIENT,
  ROLES.PUBLIC,
];

export const ROUTE_ACCESS_MAP = {
  [ROUTES.ROOT]: [...ALL_ROLES],
  [ROUTES.LOGIN]: [...ALL_ROLES],
  [ROUTES.SIGNUP]: [...ALL_ROLES],
  [ROUTES.RESET_PASSWORD]: [...ALL_ROLES],
  [ROUTES.RUNTIME]: [...ALL_ROLES],
  [ROUTES.RUNTIME_WITH_PARAM]: [...ALL_ROLES],
  [ROUTES.DASHBOARD]: [...ALL_ROLES],
  [ROUTES.CONFIGURATION]: [...ALL_ROLES],
  [ROUTES.ADMIN]: [...SUPERUSER_ROLES],
  [ROUTES.PRIVACY]: [...ALL_ROLES],
  [ROUTES.APIRO_FORMS]: [...ALL_ROLES],
  [ROUTES.APIRO_FORMS_MANAGE_CLIENTS]: [...SUPERUSER_ROLES],
  [ROUTES.APIRO_FORMS_MANAGE_CLIENTS_WITH_PARAM]: [...SUPERUSER_ROLES],
  [ROUTES.APIRO_FORMS_ADD_CLIENTS]: [...SUPERUSER_ROLES],
  [ROUTES.APIRO_FORMS_EDIT_CLIENTS]: [...SUPERUSER_ROLES],
  [ROUTES.APIRO_FORMS_EDIT_CLIENTS_WITH_PARAM]: [...SUPERUSER_ROLES],
  [ROUTES.APIRO_FORMS_MANAGE_ROLES]: [...SUPERUSER_ROLES],
  [ROUTES.APIRO_FORMS_EDIT_ROLES]: [...SUPERUSER_ROLES],
  [ROUTES.APIRO_FORMS_EDIT_ROLES_WITH_PARAM]: [...SUPERUSER_ROLES],
  [ROUTES.APIRO_FORMS_ADD_ROLES]: [...SUPERUSER_ROLES],
  [ROUTES.APIRO_FORMS_ASSIGN_FORMS]: [
    ...SUPERUSER_ROLES,
    ROLES.FINANCIALADVISERCLIENT,
  ],
  [ROUTES.APIRO_FORMS_ASSIGN_FORMS_WITH_PARAM]: [
    ...SUPERUSER_ROLES,
    ROLES.FINANCIALADVISERCLIENT,
  ],
  [ROUTES.APIRO_FORMS_VIEW_FORMS_WITH_PARAM]: [...SUPERUSER_ROLES],
  [ROUTES.APIRO_FORMS_VIEW_TYPE_WITH_PARAM]: [...SUPERUSER_ROLES],
};

export const ROUTE_VISIBILITY_MAP = {
  ...ROUTE_ACCESS_MAP,
};

Object.values(ROUTES).forEach((route) => {
  if (!ROUTE_ACCESS_MAP[route])
    throw Error(`Route doesn't have a matching route access entry ${route}`);
});

Object.values(ROUTES).forEach((route) => {
  if (!ROUTE_VISIBILITY_MAP[route])
    throw Error(
      `Route doesn't have a matching route visibility entry ${route}`
    );
});
