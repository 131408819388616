import React from 'react';
import { Redirect } from 'react-router-dom';
import { useUser } from '../../store';
import { signup as signupAPI } from '../../api/user';
import { ROUTES } from '../../constants';
import styles from './Signup.module.scss';
import { NavLink } from 'react-router-dom';

const Signup = () => {
  const [userState, userActions] = useUser();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [_, setConfirmPassword] = React.useState('');
  const [responseMessage, setResponseMessage] = React.useState('');

  const onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const onPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const onConfirmPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    setConfirmPassword(e.currentTarget.value);
  };

  const signupHandler = () => {
    signupAPI(email, password)
      .then((data) => {
        if (data) {
          const { user, role } = data;
          if (user) {
            const email = user.email as string;
            user.getIdToken().then((idToken) => {
              userActions.authenticate(email, user, idToken, role);
            });
          }
        }
      })
      .catch((e) => {
        setResponseMessage(e.message);
      });
  };

  if (userState.isAuthenticated) return <Redirect to={ROUTES.ROOT} />;

  return (
    <div className={styles.wrapper}>
      <input placeholder="email" type="text" onChange={onEmailChange}></input>
      <input
        placeholder="password"
        type="password"
        onChange={onPasswordChange}
      ></input>
      <input
        placeholder="confirm password"
        type="password"
        onChange={onConfirmPasswordChange}
      ></input>
      <button className={styles.signupBtn} onClick={signupHandler}>
        Signup
      </button>
      <NavLink className={styles.cancelLink} to={ROUTES.ROOT} exact>
        <button className={styles.signupBtn}>Cancel</button>
      </NavLink>
      <p>{responseMessage}</p>
    </div>
  );
};

export default Signup;
