import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { isPathAllowedForRole } from '../../util/helpers';

type PrivateRouteProps = RouteProps & {
  render: (props: any) => JSX.Element;
  isAuthenticated: boolean;
  role: string;
};

const PrivateRoute = ({
  isAuthenticated,
  role,
  render,
  ...rest
}: PrivateRouteProps) => {
  const { path } = rest;

  return (
    <Route
      {...rest}
      render={(props) =>
        isPathAllowedForRole(path as string, role) ? (
          render(props)
        ) : (
          <Redirect
            to={{ pathname: ROUTES.ROOT, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
