import {
  createStore,
  createSubscriber,
  createHook,
  ActionAny as RSSAction,
  defaultRegistry,
} from 'react-sweet-state';

import { FeatureFlagContent as FeatureFlagContentType } from '../api/strapi';
import { defaultFeatureFlags } from '../providers/FeatureFlagProvider/defaults';

export type FeatureFlagStoreState = {} & FeatureFlagContentType;

export type FeatureFlagActions = {
  setFeatureFlags: (
    featureFlags: FeatureFlagContentType
  ) => RSSAction<FeatureFlagStoreState, FeatureFlagActions>;
};

export const FeatureFlagStore = createStore<
  FeatureFlagStoreState,
  FeatureFlagActions
>({
  initialState: {
    ...defaultFeatureFlags,
  },
  actions: {
    setFeatureFlags: (featureFlags: FeatureFlagContentType) => ({
      setState,
    }) => {
      console.log('FeatureFlagStore content update');
      setState({
        ...featureFlags,
      });
    },
  },
  name: 'feature-flag-store',
});

export const FeatureFlagSubscriber = createSubscriber<
  FeatureFlagStoreState,
  FeatureFlagActions
>(FeatureFlagStore);

export const useFeatureFlag = createHook<
  FeatureFlagStoreState,
  FeatureFlagActions
>(FeatureFlagStore);

const getFeatureFlagStore = () =>
  // @ts-ignore calling `getStore` without providing a scopeId
  defaultRegistry.getStore<LocaleStoreState, LocaleStoreState>(
    FeatureFlagStore
  );

// non-reactive static store access without re-render concerns
// @ts-ignore accessing private store property
export const getStaticFeatureFlagState = () =>
  getFeatureFlagStore().storeState.getState();
