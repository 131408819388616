import * as firebase from 'firebase/app';
import {
  addUserRole,
  fetchUserRoleOrCreateIfNotFound,
  StrapiUserType,
} from './strapi';

// const FIREBASE_CODE_EMAIL_ALREADY_IN_USE = "auth/email-already-in-use";
// const FIREBASE_MESSAGE_EMAIL_ALREADY_IN_USE =
//   "The email address is already in use by another account.";

// const FIREBASE_CODE_INVALID_EMAIL = "auth/invalid-email";
// const FIREBASE_MESSAGE_INVALID_EMAIL = "The email address is badly formatted.";

// const FIREBASE_CODE_WRONG_PASSWORD = "auth/wrong-password";
// const FIREBASE_MESSAGE_WRONG_PASSWORD =
//   "The password is invalid or the user does not have a password.";

const googleProvider = new firebase.auth.GoogleAuthProvider();

const composeUserAndRole = (
  user: firebase.User | null,
  roleResponse: StrapiUserType | null
): { user: firebase.User; role: string } | null => {
  const role = roleResponse?.role.name;
  if (user && role) {
    return { user, role };
  }
  return null;
};

export const signup = async (
  email: string,
  password: string
): Promise<{
  user: firebase.User;
  role: string;
} | null> => {
  try {
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    const userRole = await addUserRole(email);

    return composeUserAndRole(response.user, userRole);
  } catch (e) {
    throw Error(e);
  }
};

export const login = async (
  email: string,
  password: string
): Promise<{
  user: firebase.User;
  role: string;
} | null> => {
  try {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const userRole = await fetchUserRoleOrCreateIfNotFound(email);

    return composeUserAndRole(response.user, userRole);
  } catch (e) {
    throw Error(e);
  }
};

export const loginWithGoogle = async (): Promise<{
  user: firebase.User;
  role: string;
} | null> => {
  try {
    const response = await firebase.auth().signInWithPopup(googleProvider);
    const userRole = await fetchUserRoleOrCreateIfNotFound(
      response.user?.email as string
    );

    return composeUserAndRole(response.user, userRole);
  } catch (e) {
    throw Error(e);
  }
};

export const logout = async (): Promise<void> => {
  try {
    const response = await firebase.auth().signOut();
    return response;
  } catch (e) {
    throw Error(e);
  }
};

const getFirebaseUser = (): Promise<firebase.User | null> => {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        resolve(user);
      } else {
        resolve(null);
      }
    });
  });
};

export const refreshUser = async (): Promise<{
  user: firebase.User;
  role: string;
} | null> => {
  const user = await getFirebaseUser();
  if (user) {
    console.info('User already logged in');
    const userRole = await fetchUserRoleOrCreateIfNotFound(
      user.email as string
    );
    return composeUserAndRole(user, userRole);
  } else {
    console.info('User not logged in');
    return null;
  }
};

export const resetPassword = async (email: string): Promise<void> => {
  return await firebase.auth().sendPasswordResetEmail(email);
};
