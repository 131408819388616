import {
  createStore,
  createSubscriber,
  createHook,
  ActionAny as RSSAction,
  defaultRegistry,
} from 'react-sweet-state';

import {
  PublicContent as PublicContentType,
  UserContent as UserContentType,
} from '../api/strapi';
import { LocaleValuesFromStrapi } from '../types';

export type LocaleStoreState = {
  locale: string;
  logoURL: string;
  publicContentReady: boolean;
  userContentReady: boolean;
  values?: LocaleValuesFromStrapi;
};

export type LocaleStoreActions = {
  setPublicContent: (
    content: PublicContentType
  ) => RSSAction<LocaleStoreState, LocaleStoreActions>;
  setUserContent: (
    content: UserContentType
  ) => RSSAction<LocaleStoreState, LocaleStoreActions>;
};

export const LocaleStore = createStore<LocaleStoreState, LocaleStoreActions>({
  initialState: {
    locale: 'en',
    logoURL: '',
    publicContentReady: false,
    userContentReady: false,
    // TODO: gotte reinforce defaults for values
  },
  actions: {
    setPublicContent: (content: PublicContentType) => ({ setState }) => {
      console.log('LocaleStore public content update');
      setState({
        logoURL: content.logoURL,
        publicContentReady: true,
      });
    },
    setUserContent: (content: UserContentType) => ({ setState }) => {
      console.log('LocaleStore user content update');
      setState({
        locale: content.locale,
        values: content.values,
        userContentReady: true,
      });
    },
  },
  name: 'locale-store',
});

export const LocaleSubscriber = createSubscriber<
  LocaleStoreState,
  LocaleStoreActions
>(LocaleStore);

export const useLocale = createHook<LocaleStoreState, LocaleStoreActions>(
  LocaleStore
);

const getLocaleStore = () =>
  // @ts-ignore calling `getStore` without providing a scopeId
  defaultRegistry.getStore<LocaleStoreState, LocaleStoreState>(LocaleStore);

// non-reactive static store access without re-render concerns
// @ts-ignore accessing private store property
export const getStaticLocaleState = () =>
  getLocaleStore().storeState.getState();
