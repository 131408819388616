import React, { useState } from 'react';
import styles from './UsersAddEdit.module.scss';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  addEditUser,
  fetchUsers,
  uploadProfileImage,
} from '../../../../api/strapi';
import { useHistory } from 'react-router-dom';
import CustomInput from '../../../../components/CustomInput';
import { useUser } from '../../../../store';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { LocaleStoreState } from '../../../../store/locale-store';
import { BsPeopleCircle } from 'react-icons/bs';
import { STRAPI } from '../../../../constants';

type UsersAddEditProps = {
  locale: LocaleStoreState;
  match: any;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 350,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const UsersAddEdit = ({ locale, match }: UsersAddEditProps) => {
  const history: any = useHistory();

  const [user, userActions] = useUser();

  const [username, setUsername] = useState('');
  const [isUsernameValid, setIsUsernameValid]: any = useState(null);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid]: any = useState(null);
  const [selectedRoles, setselectedRoles]: any[] = useState([]);
  const [profileImage, setProfileImage]: any = useState(null);
  const [profileImageUrl, setProfileImageUrl]: any = useState(null);
  const [firstName, setFirstName] = useState('');
  const [isFirstNameValid, setIsFirstNameValid]: any = useState(null);
  const [lastName, setLastName] = useState('');
  const [isLastNameValid, setIsLastNameValid]: any = useState(null);

  React.useEffect(() => {
    if (match.params.id) {
      let index: any = user.usersList.findIndex(
        (item: any) => item.id == match.params.id
      );
      let userData: any = user.usersList[index];
      if (userData) {
        onUsernameChange(userData?.username);
        onEmailChange(userData?.email);
        onFirstNameChange(userData?.first_name || '');
        onLastNameChange(userData?.last_name || '');
        setProfileImageUrl(
          userData?.profile_image?.url
            ? `${STRAPI.BASE_URL}${userData?.profile_image?.url}`
            : ''
        );

        selectedRoles.push(userData?.role?.id);
        setselectedRoles([...selectedRoles]);
      }
    }
  }, []); // eslint-disable-line

  const onUsernameChange = (username: string) => {
    setIsUsernameValid(!!username);
    setUsername(username);
  };

  const onEmailChange = (email: string) => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setIsEmailValid(!!email && re.test(email));
    setEmail(email);
  };

  const onRolesSelection = (e: any) => {
    setselectedRoles(e.target.value);
  };

  const handleProfileImage = async (e: any) => {
    // console.log(URL.createObjectURL(e.target.files[0]));
    setProfileImageUrl(URL.createObjectURL(e.target.files[0]));
    setProfileImage(e.target.files[0]);
  };

  const onFirstNameChange = (firstName: string) => {
    setIsFirstNameValid(!!firstName);
    setFirstName(firstName);
  };

  const onLastNameChange = (lastName: string) => {
    setIsLastNameValid(!!lastName);
    setLastName(lastName);
  };

  const handleAddOrEditUser = () => {
    if (
      (isUsernameValid ??
        isFirstNameValid ??
        isLastNameValid ??
        isEmailValid) &&
      isEmailValid &&
      isUsernameValid &&
      isFirstNameValid &&
      isLastNameValid
    ) {
      addEditUser(match.params.id ? false : true, {
        id: match.params.id,
        username,
        email,
        role: selectedRoles[0], // TEMPORARY: Because API is yet to be changed to accept multiple roles.
        firstName,
        lastName,
        profileImage,
      }).then((userData: any) => {
        uploadProfileImage(profileImage, userData.id).then((data: any) => {
          // console.log(data);
        });
        fetchUsers().then((data: any) => {
          userActions.setUsersList([...data]);
        });
        history.goBack();
      });
    } else {
      onUsernameChange(username);
      onEmailChange(email);
      onFirstNameChange(firstName);
      onLastNameChange(lastName);
    }
  };
  const classes = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <div className={styles.avatarWrapper}>
          <div className={styles.uploadIcon}>
            {profileImageUrl ? (
              <img className={styles.profileImage} src={profileImageUrl} />
            ) : (
              <BsPeopleCircle size={100} color={'#e0e0e0'} />
            )}
          </div>
          <input
            className={styles.fileUpload}
            type="file"
            accept="image/*"
            onChange={(e: any) => handleProfileImage(e)}
          />
        </div>
      </div>
      <div className={styles.inputContainer}>
        <CustomInput
          required={true}
          label={locale.values?.label?.usernameLabel}
          value={username}
          onChange={(e: any) => onUsernameChange(e.target.value)}
          placeholder={locale.values?.label?.usernameLabel}
          errorMessage={
            isUsernameValid != null && isUsernameValid != undefined
              ? isUsernameValid
                ? ''
                : locale.values?.label?.usernameRequired
              : ''
          }
        />
      </div>
      <div className={styles.inputContainer}>
        <CustomInput
          required={true}
          label={locale.values?.label?.firstNameLabel}
          value={firstName}
          onChange={(e: any) => onFirstNameChange(e.target.value)}
          placeholder={locale.values?.label?.firstNameLabel}
          errorMessage={
            isFirstNameValid != null && isFirstNameValid != undefined
              ? isFirstNameValid
                ? ''
                : locale.values?.label?.firstNameRequired
              : ''
          }
        />
      </div>
      <div className={styles.inputContainer}>
        <CustomInput
          required={true}
          label={locale.values?.label?.lastNameLabel}
          value={lastName}
          onChange={(e: any) => onLastNameChange(e.target.value)}
          placeholder={locale.values?.label?.lastNameLabel}
          errorMessage={
            isLastNameValid != null && isLastNameValid != undefined
              ? isLastNameValid
                ? ''
                : locale.values?.label?.lastNameRequired
              : ''
          }
        />
      </div>
      <div className={styles.inputContainer}>
        <CustomInput
          required={true}
          label={locale.values?.label?.emailLabel}
          value={email}
          onChange={(e: any) => onEmailChange(e.target.value)}
          placeholder={locale.values?.label?.emailLabel}
          errorMessage={
            isEmailValid != null && isEmailValid != undefined
              ? isEmailValid
                ? ''
                : locale.values?.label?.emailRequired
              : ''
          }
        />
      </div>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
          <Select
            multiple
            value={selectedRoles}
            onChange={onRolesSelection}
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            renderValue={(selected: any) => (
              <div className={classes.chips}>
                {selected.map((value: any) => {
                  let roleIndex: number = user.applicationRolesList.findIndex(
                    (role) => role.id === value
                  );
                  let roleName: string =
                    user.applicationRolesList[roleIndex].name;
                  return (
                    <Chip
                      key={roleName}
                      label={roleName}
                      className={classes.chip}
                    />
                  );
                })}
              </div>
            )}
          >
            {user.applicationRolesList.map((role: any) => (
              <MenuItem key={role.id} value={role.id}>
                <Checkbox checked={selectedRoles.indexOf(role.id) > -1} />
                <ListItemText primary={role.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={styles.inputContainer}>
        <button className={styles.saveBtn} onClick={handleAddOrEditUser}>
          {locale.values?.label?.saveLabel}
        </button>
      </div>
    </div>
  );
};

export default UsersAddEdit;
