import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LocaleStoreState } from '../../store/locale-store';
import Header from '../../components/Header';
import styles from './Admin.module.scss';

type AdminProps = RouteComponentProps<{}> & {
  locale: LocaleStoreState;
};

function Admin(props: AdminProps) {
  return (
    <React.Fragment>
      <Header locale={props.locale} />
      <div className={styles.pageWrapper}>
        Admin page - only users with role FinancialAdviser/SuperAdmin can access
      </div>
    </React.Fragment>
  );
}

export default Admin;
