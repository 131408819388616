import React from 'react';

import FeatureFlagProvider from './providers/FeatureFlagProvider';
import LocaleProvider from './providers/LocaleProvider';
import AuthProvider from './providers/AuthProvider';

function AppProviders({ children }: { children: React.ReactNode }) {
  return (
    <FeatureFlagProvider>
      <LocaleProvider>
        <AuthProvider>{children}</AuthProvider>
      </LocaleProvider>
    </FeatureFlagProvider>
  );
}

export default AppProviders;
