import React from 'react';
import TableProvider from './TableProvider';
import Table from './Table';

const DataTable = () => {
  return (
    <TableProvider>
      {({ columns, data }: any) => {
        return <Table columns={columns} data={data} />;
      }}
    </TableProvider>
  );
};

export default DataTable;
