import React, { useState } from 'react';
import CustomTable from '../../../../components/CustomTable';
import { fetchForms } from '../../../../api/strapi';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from '../../../../components/CustomInput';
import styles from './ManageForms.module.scss';
import { LocaleStoreState } from '../../../../store/locale-store';
import { addNewForm } from '../../../../api/strapi';
import { NavLink } from 'react-router-dom';
import { useForm } from '../../../../store';
import { useHistory } from 'react-router-dom';

type ManageFormsProps = {
  locale: LocaleStoreState;
};

const ManageForms = ({ locale }: ManageFormsProps) => {
  const [tableData, setTableData]: any = useState([]);
  const [openNewFormDialog, setOpenNewFormDialog] = useState(false);
  const [category, setCategory] = useState('');
  const [isCategoryValid, setIsCategoryValid]: any = useState(null);
  const [documentName, setDocumentName] = useState('');
  const [isDocumentNameValid, setIsDocumentNameValid]: any = useState(null);
  const [documentTemplate, setDocumentTemplate]: any = useState(null);
  const [isDocumentTemplateValid, setIsDocumentTemplateValid]: any = useState(
    null
  );
  const [metadataMapping, setMetadataMapping]: any = useState(
    JSON.stringify({
      Text21: {
        type: 'plaintext',
        value: 'AA',
      },
      Text25: {
        type: 'plaintext',
        value: 'AB',
      },
    })
  );
  const [isMetadataMappingValid, setIsMetadataMappingValid]: any = useState(
    true
  );
  const [form, formActions] = useForm();
  const history: any = useHistory();

  React.useEffect(() => {
    setTableData([...form.formsList]);
  }, [form.formsList]); // eslint-disable-line react-hooks/exhaustive-deps

  const headers: any[] = [
    {
      type: 'text',
      key: 'doc_category',
      value: locale.values?.label?.categoryLabel,
    },
    {
      type: 'text',
      key: 'doc_name',
      value: locale.values?.label?.nameLabel,
    },
    {
      type: 'actionContainer',
      key: 'actionContainer',
      value: locale.values?.label?.pdfFileLabel,
      actionContainer: (props: any) => (
        <NavLink
          className={styles.navAnchor}
          activeClassName={styles.navActive}
          to={`/apiroforms/forms/viewtype/pdf/${props.itemData.id}`}
        >
          {locale.values?.label?.viewPdfLabel}
        </NavLink>
      ),
    },
    {
      type: 'actionContainer',
      key: 'actionContainer',
      value: locale.values?.label?.pdfFileLabel,
      actionContainer: (props: any) => (
        <NavLink
          className={styles.navAnchor}
          activeClassName={styles.navActive}
          to={`/apiroforms/forms/viewtype/json/${props.itemData.id}`}
        >
          {locale.values?.label?.viewMetadataLabel}
        </NavLink>
      ),
    },
    {
      type: 'actionsListIcon',
      key: 'actions',
      value: locale.values?.label?.actionsLabel,
      actions: [
        {
          key: 'remove',
          value: locale.values?.label?.comparePdfAndMetadataLabel,
          callback: (formData: any) =>
            history.push(`/apiroforms/forms/viewtype/pdfjson/${formData.id}`),
        },
      ],
    },
  ];

  const handleOpenNewFormDialog = () => {
    setOpenNewFormDialog(true);
  };

  const handleCloseNewFormDialog = () => {
    setOpenNewFormDialog(false);
  };

  const onDocumentNameChange = (documentName: string) => {
    setIsDocumentNameValid(!!documentName);
    setDocumentName(documentName);
  };

  const onCategoryChange = (category: string) => {
    setIsCategoryValid(!!category);
    setCategory(category);
  };

  const onMetadatMappingChange = (metadatMapping: string) => {
    setIsMetadataMappingValid(!!metadatMapping);
    setMetadataMapping(metadatMapping);
  };

  const handleFile = async (file: any) => {
    setIsDocumentTemplateValid(!!file);
    setDocumentTemplate(file);
  };

  const handleAddNewForm = () => {
    if (
      (isCategoryValid ??
        isDocumentNameValid ??
        isDocumentTemplateValid ??
        isMetadataMappingValid) &&
      isCategoryValid &&
      isDocumentNameValid &&
      isDocumentTemplateValid &&
      isMetadataMappingValid
    ) {
      addNewForm(
        documentName,
        category,
        documentTemplate,
        metadataMapping
      ).then(() => {
        fetchForms().then((data: any) => {
          formActions.setFormsList([...data]);
        });
        setOpenNewFormDialog(false);

        onDocumentNameChange('');
        onCategoryChange('');
        handleFile(null);
      });
    } else {
      onCategoryChange(category);
      onDocumentNameChange(documentName);
      onMetadatMappingChange(metadataMapping);
      handleFile(null);
    }
  };

  return (
    <div>
      <div className={styles.toolbar}>
        <h3>{locale.values?.label?.formsLabel}</h3>
        <div>
          <button
            className={styles.addNewFormBtn}
            onClick={handleOpenNewFormDialog}
          >
            {locale.values?.label?.addNewFormLabel}
          </button>
          <NavLink
            className={styles.navAnchor}
            activeClassName={styles.navActive}
            to="/apiroforms/forms/assign"
          >
            <button className={styles.assignFormsBtn}>
              {locale.values?.label?.assignFormsLabel}
            </button>
          </NavLink>
        </div>
      </div>
      <CustomTable headers={headers} tableData={tableData} />

      <Dialog
        open={openNewFormDialog}
        onClose={handleCloseNewFormDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Add new form</DialogTitle>
        <DialogContent>
          <CustomInput
            required={true}
            label={locale.values?.label?.categoryLabel}
            value={category}
            onChange={(e: any) => onCategoryChange(e.target.value)}
            placeholder={locale.values?.label?.categoryLabel}
            errorMessage={
              isCategoryValid != null && isCategoryValid != undefined
                ? isCategoryValid
                  ? ''
                  : locale.values?.label?.categoryRequired
                : ''
            }
          />
          <CustomInput
            required={true}
            label={locale.values?.label?.documentNameLabel}
            value={documentName}
            onChange={(e: any) => onDocumentNameChange(e.target.value)}
            placeholder={locale.values?.label?.documentNameLabel}
            errorMessage={
              isDocumentNameValid != null && isDocumentNameValid != undefined
                ? isDocumentNameValid
                  ? ''
                  : locale.values?.label?.documentNameRequired
                : ''
            }
          />
          <CustomInput
            required={true}
            label={locale.values?.label?.metadatMappingLabel}
            multiline={true}
            rows={4}
            value={metadataMapping}
            onChange={(e: any) =>
              onMetadatMappingChange(JSON.stringify(JSON.parse(e.target.value)))
            }
            placeholder={locale.values?.label?.metadatMappingLabel}
            errorMessage={
              isMetadataMappingValid != null &&
              isMetadataMappingValid != undefined
                ? isMetadataMappingValid
                  ? ''
                  : locale.values?.label?.metadatMappinRequired
                : ''
            }
          />
          <input
            onChange={(e: any) => handleFile(e.target.files[0])}
            type="file"
            name="files"
          />
        </DialogContent>
        <DialogActions>
          <button
            className={styles.cancelBtn}
            disabled={false}
            onClick={handleCloseNewFormDialog}
          >
            {locale.values?.label?.cancelLabel}
          </button>
          <button className={styles.saveBtn} onClick={handleAddNewForm}>
            {locale.values?.label?.saveLabel}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManageForms;
