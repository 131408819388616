import React from 'react';
import styles from './ManageRoles.module.scss';
import RolesList from '../RolesList';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { deleteRole, fetchRoles } from '../../../../api/strapi';
import { useUserActions } from '../../../../store/user-store';
import { LocaleStoreState } from '../../../../store/locale-store';

type ManageUsersProps = {
  locale: LocaleStoreState;
};

const ManageRoles = ({ locale }: ManageUsersProps) => {
  const history: any = useHistory();
  const [_, userActions] = useUserActions();

  const headers: any[] = [
    {
      type: 'text',
      key: 'name',
      value: locale.values?.label?.nameLabel,
    },
    {
      type: 'text',
      key: 'description',
      value: locale.values?.label?.descriptionLabel,
    },
    {
      type: 'actionText',
      key: 'nb_users',
      value: locale.values?.label?.noOfUsersLabel,
      baseUrl: '/apiroforms/clients/manage',
      urlParamKey: 'id',
    },
    {
      type: 'actionsListIcon',
      key: 'actions',
      value: locale.values?.label?.actionsLabel,
      actions: [
        {
          key: 'edit',
          value: 'Edit',
          callback: (roleData: any) =>
            history.push(`/apiroforms/roles/edit/${roleData.id}`),
        },
        {
          key: 'remove',
          value: locale.values?.label?.removeLabel,
          callback: (roleData: any) => {
            if (roleData.nb_users === 0) {
              deleteRole(roleData.id).then(() => {
                fetchRoles().then((data: any) => {
                  userActions.setApplicationsRolesList(data.roles);
                });
              });
            } else {
              alert(locale.values?.label?.cannotDeleteRoleMessage);
            }
          },
        },
      ],
    },
  ];

  return (
    <div>
      <div className={styles.toolbar}>
        <h3>{locale.values?.label?.rolesLabel}</h3>
        <NavLink
          className={styles.navAnchor}
          activeClassName={styles.navActive}
          to="/apiroforms/roles/add"
        >
          <button className={styles.addClientBtn}>
            {locale.values?.label?.addRoleLabel}
          </button>
        </NavLink>
      </div>
      <RolesList headers={headers} />
    </div>
  );
};

export default ManageRoles;
