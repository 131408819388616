import React, { useState } from 'react';
import { fetchOneForm } from '../../../../api/strapi';
import CustomInput from '../../../../components/CustomInput';
import styles from './PDFWebForm.module.scss';
import { LocaleStoreState } from '../../../../store/locale-store';
import SignaturePad from 'react-signature-canvas';

type PDFWebFormProps = {
  locale: LocaleStoreState;
  match: any;
};

const PDFWebForm = ({ locale, match }: PDFWebFormProps) => {
  const [formData, setFormData]: any = useState({});
  const [sigPad, setSigPad]: any = useState({});
  const [trimmedDataURL, setTrimmedDataURL]: any = useState(null);

  React.useEffect(() => {
    fetchOneForm(match.params.id).then((data) => {
      setFormData({ ...data });
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clear = () => {
    sigPad.clear();
  };

  const handleSubmitForm = () => {
    setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL('image/png'));
    // console.log(trimmedDataURL);
    // console.log(formData.metadata_mapping);
  };

  return (
    <div className={styles.mainContainer}>
      <div>
        <h3>{formData && formData.doc_name}</h3>
        {formData &&
          formData.metadata_mapping &&
          Object.keys(formData.metadata_mapping).map(
            (field: any, index: number) => (
              <CustomInput
                key={`${index}${field.key}`}
                required={true}
                label={formData.metadata_mapping[field].value}
                // value={documentName}
                onChange={(e: any) =>
                  (formData.metadata_mapping[field].data = e.target.value)
                }
                placeholder={formData.metadata_mapping[field].value}
              />
            )
          )}
      </div>
      <div className={styles.sigContainer}>
        <SignaturePad
          canvasProps={{ className: styles.sigPad }}
          ref={(ref: any) => setSigPad(ref)}
        />
      </div>
      <div className={styles.bottomContainer}>
        <button className={styles.clearSignatureBtn} onClick={clear}>
          {locale.values?.label?.clearSignatureLabel}
        </button>
        <button className={styles.saveBtn} onClick={handleSubmitForm}>
          {locale.values?.label?.saveLabel}
        </button>
      </div>
    </div>
  );
};

export default PDFWebForm;
