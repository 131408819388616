import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import styles from './ApiroForms.module.scss';
import { Switch, Route } from 'react-router-dom';
import { LocaleStoreState } from '../../store/locale-store';
import { ROUTES } from '../../constants';
import ManageForms from './Forms/ManageForms';
import ManageUsers from './Users/ManageUsers';
import UsersAddEdit from './Users/UsersAddEdit';
import ManageRoles from './Roles/ManageRoles';
import RolesAddEdit from './Roles/RolesAddEdit';
import Logo from '../../components/Logo';
import AssignForms from './Forms/AssignForms';
import PDFWebForm from './Forms/PDFWebForm';
import PdfAndJsonView from './Forms/PdfAndJsonView';
import { useHistory } from 'react-router-dom';
import { VscArrowLeft } from 'react-icons/vsc';

type ApiroFormsProps = RouteComponentProps<{}> & {
  locale: LocaleStoreState;
};

const ApiroForms = (apiroFormsProps: ApiroFormsProps) => {
  const history: any = useHistory();
  return (
    <React.Fragment>
      <header className={styles.tabsHeader}>
        <div className={styles.backBtn} onClick={() => history.goBack()}>
          <VscArrowLeft size={25} />
        </div>
        <Link to={ROUTES.ROOT}>
          <div className={styles.logoWrapper}>
            <Logo logoURL={apiroFormsProps.locale.logoURL} />
          </div>
        </Link>
      </header>

      <Switch>
        <Route
          exact
          path={ROUTES.APIRO_FORMS}
          render={(props: any) => (
            <ManageForms locale={apiroFormsProps.locale} {...props} />
          )}
        />
        <Route
          exact
          path={ROUTES.APIRO_FORMS_MANAGE_CLIENTS_WITH_PARAM}
          render={(props: any) => (
            <ManageUsers {...apiroFormsProps} {...props} />
          )}
        />
        <Route
          exact
          path={ROUTES.APIRO_FORMS_ADD_CLIENTS}
          render={(props: any) => (
            <UsersAddEdit {...apiroFormsProps} {...props} />
          )}
        />
        <Route
          exact
          path={ROUTES.APIRO_FORMS_EDIT_CLIENTS_WITH_PARAM}
          render={(props: any) => (
            <UsersAddEdit {...apiroFormsProps} {...props} />
          )}
        />
        <Route
          exact
          path={ROUTES.APIRO_FORMS_MANAGE_ROLES}
          render={(props: any) => (
            <ManageRoles {...apiroFormsProps} {...props} />
          )}
        />
        <Route
          exact
          path={ROUTES.APIRO_FORMS_ADD_ROLES}
          render={(props: any) => (
            <RolesAddEdit {...apiroFormsProps} {...props} />
          )}
        />
        <Route
          exact
          path={ROUTES.APIRO_FORMS_EDIT_ROLES_WITH_PARAM}
          render={(props: any) => (
            <RolesAddEdit {...apiroFormsProps} {...props} />
          )}
        />
        <Route
          exact
          path={ROUTES.APIRO_FORMS_ASSIGN_FORMS}
          render={(props: any) => (
            <AssignForms locale={apiroFormsProps.locale} {...props} />
          )}
        />
        <Route
          exact
          path={ROUTES.APIRO_FORMS_ASSIGN_FORMS_WITH_PARAM}
          render={(props: any) => (
            <AssignForms locale={apiroFormsProps.locale} {...props} />
          )}
        />
        <Route
          exact
          path={ROUTES.APIRO_FORMS_VIEW_FORMS_WITH_PARAM}
          render={(props: any) => (
            <PDFWebForm locale={apiroFormsProps.locale} {...props} />
          )}
        />
        <Route
          exact
          path={ROUTES.APIRO_FORMS_VIEW_TYPE_WITH_PARAM}
          render={(props: any) => (
            <PdfAndJsonView locale={apiroFormsProps.locale} {...props} />
          )}
        />
      </Switch>
    </React.Fragment>
  );
};

export default ApiroForms;
