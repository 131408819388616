import React from 'react';
import styles from './Sidebar.module.scss';
import { FiDatabase } from 'react-icons/fi';
import { CgNotes, CgGitBranch, CgStack } from 'react-icons/cg';

const Sidebar = () => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.iconWrapper}>
        <FiDatabase />
      </div>
      <div className={styles.iconWrapper}>
        <CgNotes />
      </div>
      <div className={styles.iconWrapper}>
        <CgGitBranch />
      </div>
      <div className={styles.iconWrapper}>
        <CgStack />
      </div>
    </div>
  );
};

export default Sidebar;
