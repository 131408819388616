import { mockRatesRes } from './mock-response';

type RatesType = any;

export const fetchRates = async (): Promise<RatesType> => {
  try {
    await Promise.resolve();
    const data = mockRatesRes.rates;

    return data;
  } catch (e) {
    throw e;
  }
};
